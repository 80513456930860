import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useForm } from 'react-hook-form'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'
const AddEmployeeTask= () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const [emData, setEmData] = useState([])
  const [evData, setEvData] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    GetDataWithToken('getAllEmployee').then(res => {
      if (res?.success) {
        setEmData(res?.data)
      } else {
        console.log('Error', res?.message)
      }
    })
    GetDataWithToken('getallevent').then(res => {
      if (res?.success) {
        setEvData(res?.data)
      } else {
        console.log('Error', res?.message)
      }
    })
  }, [])
  const handleAddEmp = data => {
    setLoading(true);
    PostDataWithToken('createemployeetask', data)
      .then(res => {
        if (res?.success) {
          toast.success(res?.message);
          navigate('/employe-task');
        } else {
          toast.error(res?.message || 'Failed to create employee task');
        }
      })
      .catch(error => {
        console.error('Error creating employee task:', error);
        toast.error('Failed to create employee task');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      { loading? (
      <>
      <Loader/>
      </>
      ) : (
        <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='card mb-4'>
                <div className='card-header pb-0'>
                  <h6>Add Employee Task</h6>
                </div> 
                <div className='card-body px-0 pt-0 pb-2'>
                  <div className=''>
                    <div className=''>
                      <div className='card-body'>
                        <form role='form' onSubmit={handleSubmit(handleAddEmp)}>
                          <div className='row'>
                            {/* Employee Type */}
                            {/* Reporting Manager */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Employee Name</label>
                              <div className='mb-3'>
                                <select
                                  className='form-control'
                                  id='empid'
                                  name='empid'
                                  {...register('empid', {
                                    required: 'Reporting Manager is required'
                                  })}
                                >
                                  <option key='' value=''>
                                    select Employe
                                  </option>
                                  {emData?.map((item, index) => (
                                    <option key={index} value={item?.id}>
                                      {item?.first_name + ' ' + item?.last_name}
                                    </option>
                                  ))}
                                </select>
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.empid &&
                                      errors?.empid?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}                 
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Event Name</label>
                              <div className='mb-3'>
                                <select
                                  className='form-control'
                                  id='eventid'
                                  name='eventid'
                                  {...register('eventid', {
                                    required: ' Event Name is required'
                                  })}
                                >
                                  <option key='' value=''>
                                    select Event Name
                                  </option>
                                  {evData?.map((item, index) => (
                                    <option key={index} value={item?.id}>
                                      {item?.eventName}
                                    </option>
                                  ))}
                                </select>
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.eventid &&
                                      errors?.eventid?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                          
                             {/* Start Time */}
                             <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Task Start Date</label>
                              <div className='mb-3'>
                                <input
                                  type='date'
                                  className='form-control'
                                  placeholder='Start Time'
                                  aria-label='task_start_time'
                                  aria-describedby='text-addon'
                                  id='taskStartTime'
                                  name='taskStartTime'
                                  {...register('taskStartTime', {
                                    required: 'Start Time is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.taskStartTime&&
                                      errors?.taskStartTime?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* End Time */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Task End Date</label>
                              <div className='mb-3'>
                                <input
                                  type='date'
                                  className='form-control'
                                  placeholder='End Time'
                                  aria-label='End Time'
                                  aria-describedby='text-addon'
                                  id='taskEndTime'
                                  name='taskEndTime'
                                  {...register('taskEndTime', {
                                    required: 'End Time is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.taskEndTime &&
                                      errors?.taskEndTime?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* City */}
                            
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Task Localtion</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='location'
                                  aria-label='City'
                                  aria-describedby='text-addon'
                                  id='taskLocation'
                                  name='taskLocation'
                                  {...register('taskLocation', {
                                    required: 'Task location is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.taskLocation && errors?.taskLocation?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Event status</label>
                              <div className='mb-3'>
                                <select
                                  className='form-control'
                                  id='status'
                                  name='status'
                                  {...register('status', {
                                    required: 'Employee Status is required'
                                  })}
                                >
                                  <option key='' value=''>
                                    select Status
                                  </option>
                                
                                    <option value="active">
                                     Active
                                    </option>
                                    <option value="  inactive">
                                     Inactive
                                    </option>
                           
                                </select>
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.status &&
                                      errors?.status?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              type='submit'
                              className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) }
    
      {/* </main> */}
    </Layout>
  )
}

export default AddEmployeeTask

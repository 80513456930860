import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useNavigate } from 'react-router-dom'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import Loader from '../../common/Loader'

const EmployeeTask = () => {
  const navigate = useNavigate()
  const [empTaskData, setEmpTaskData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const toggleDeleteModal = () => setDeleteModal(!deleteModal)
  const [emptaskId, setEmptaskId] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getEmptask()
  }, [])

  const getEmptask = () => {
    setLoading(true)
    GetDataWithToken('getallemptask')
      .then(res => {
        setLoading(false) // Stop loading when the response is received
        if (res?.success) {
          setEmpTaskData(res?.data)
          setPageCount(Math.ceil(res?.data.length / itemsPerPage))
        } else {
          toast.error(res?.message)
        }
      })
      .catch(err => {
        setLoading(false) // Stop loading in case of an error
        console.error(err)
        toast.error('An error occurred while fetching employee tasks')
      })
  }

  const handleDelete = id => {
    setLoading(true)
    PostDataWithToken(`deleteemptask/${id}`)
      .then(res => {
        setLoading(false) // Stop loading when the response is received
        if (res?.success) {
          toast.success(res?.message)
          getEmptask()
          toggleDeleteModal()
        } else {
          toast.error(res?.message)
        }
      })
      .catch(err => {
        setLoading(false) // Stop loading in case of an error
        console.error(err)
        toast.error('An error occurred while deleting the task')
      })
  }

  // Pagination Start
  const [pageCount, setPageCount] = useState()
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [itemOffset, setItemOffset] = useState(0)

  const handlePageClick = event => {
    const newOffset = event.selected * itemsPerPage
    setItemOffset(newOffset)
  }

  const endOffset = itemOffset + itemsPerPage
  const currentItems = empTaskData.slice(itemOffset, endOffset)
  const handleChangeItemPerPage = e => {
    setItemsPerPage(e)
  }
  // Pagination End

  return (
    <Layout>
      {/* <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg "> */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className='container-fluid py-4 dash_main'>
            <div className='row'>
              <div className='col-12'>
                <div className='card mb-4 p-3'>
                  <button
                    className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                    onClick={() => navigate('/add-employe-task')}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='card mb-4'>
                  <div className='card-header pb-0'>
                    <h6>Employee Task</h6>
                  </div>
                  <div className='card-body pt-0 pb-2'>
                    <div className='table-responsive p-0'>
                      <table className='table align-items-center mb-0'>
                        <thead>
                          <tr>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Employee Name
                            </th>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Event Name
                            </th>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Task Location
                            </th>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Status
                            </th>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Action
                            </th>
                            {/* <th className='text-secondary opacity-7'>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  <h6 className='mb-0 text-sm'>
                                    {item?.empname}
                                  </h6>
                                </td>
                                <td>
                                  <p className='text-xs font-weight-bold mb-0'>
                                    {item?.eventname}
                                  </p>
                                </td>
                                <td>
                                  <p className='text-xs font-weight-bold mb-0'>
                                    {item?.taskLocation}
                                  </p>
                                </td>
                                <td>
                                  <p className='text-xs font-weight-bold mb-0'>
                                    {item?.status}
                                  </p>
                                </td>
                                <td className='align-middle'>
                                  <div
                                    className='text-secondary font-weight-bold text-xs'
                                    data-toggle='tooltip'
                                    data-original-title='Edit user'
                                  >
                                    <div className='d-flex gap-4'>
                                      <div
                                        onClick={() =>
                                          navigate('/view-employe-task', {
                                            state: item
                                          })
                                        }
                                      >
                                        <img
                                          src='.\assets\img\info.png'
                                          style={{
                                            width: '20px',
                                            color: 'red'
                                          }}
                                          alt=''
                                        />
                                      </div>
                                      <div
                                        onClick={() =>
                                          navigate('/edit-employe-task', {
                                            state: item
                                          })
                                        }
                                      >
                                        <img
                                          src='.\assets\img\pencil.png'
                                          style={{ width: '15px' }}
                                          alt=''
                                        />
                                      </div>
                                      <div
                                        onClick={() => {
                                          toggleDeleteModal()
                                          setEmptaskId(item?.id)
                                        }}
                                      >
                                        <img
                                          src='.\assets\img\delete.png'
                                          style={{ width: '15px' }}
                                          alt=''
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='card mb-4 p-3'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <div>
                      <div>
                        <p>
                          Showing 1 - {itemsPerPage} items of{' '}
                          {empTaskData?.length}
                        </p>
                      </div>
                    </div>
                    <div className='main-page-item'>
                      <div className='show-page'>
                        <select
                          className='form-control'
                          onClick={e =>
                            handleChangeItemPerPage(e?.target?.value)
                          }
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>

                      <div>
                        <ReactPaginate
                          breakLabel='...'
                          nextLabel='>'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel='<'
                          renderOnZeroPageCount={null}
                          pageClassName='page-item'
                          pageLinkClassName='page-link'
                          previousClassName='page-item'
                          previousLinkClassName='page-link'
                          nextClassName='page-item'
                          nextLinkClassName='page-link'
                          breakClassName='page-item'
                          breakLinkClassName='page-link'
                          containerClassName='pagination'
                          activeClassName='active'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={deleteModal}
            toggle={toggleDeleteModal}
            centered
            backdrop={false}
            className='logout_modal'
          >
            <ModalBody>
              <p>Are you sure you want to delete this EmployeeTask!!</p>
              <div className='modal_btn'>
                <button
                  className='btn btn-danger'
                  onClick={() => toggleDeleteModal()}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-success'
                  onClick={() => handleDelete(emptaskId)}
                >
                  Confirm
                </button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}

      {/* <Footer /> */}
      {/* </main> */}
    </Layout>
  )
}

export default EmployeeTask

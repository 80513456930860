import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({ data, itemsPerPage }) => {
  // Calculate the number of pages based on data length and itemsPerPage
  const pageCount = Math.ceil(data.length / itemsPerPage)

  // Handle page change
  const handlePageClick = event => {
    const newOffset = event.selected * itemsPerPage
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`)
    // Update item offset
    setItemOffset(newOffset)
  }

  // Use item offset to slice the current items to display
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const currentItems = data.slice(itemOffset, endOffset)

  // Render current items
  const Items = ({ currentItems }) => (
    <>
      {currentItems.map((item, index) => (
        <div key={index}>
          <h3>Item #{item.id}</h3> {/* Adjust this line to display appropriate item data */}
        </div>
      ))}
    </>
  )

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel='...'
        nextLabel='next >'
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel='< previous'
        renderOnZeroPageCount={null}
      />
    </>
  )
}

export default Pagination

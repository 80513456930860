import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'

const EmployeeDetails = () => {
  const location = useLocation()
  const [data, setData] = useState()
  const [eventData, setEventData] = useState([])
  const navigate = useNavigate()
  const [eventId, setEventId] = useState('')
  const [loading, setLoading] = useState('')
  const viewDetails = () => {
    setLoading(true) // Set loading to true at the start
  
    if (!location?.state?.id) {
      toast.error("Employee ID is missing.")
      setLoading(false) // Stop loading if employee ID is missing
      return
    }
  
    GetDataWithToken(`employee/${location.state.id}`).then(res => {
      setLoading(false) // Stop loading when the response is received
      if (res?.success) {
        setData(res?.data)
      } else {
        toast.error(res?.message)
      }
    }).catch(err => {
      setLoading(false) // Stop loading in case of an error
      console.error(err)
      toast.error('An error occurred while fetching employee details')
    })
  }
  

  useEffect(() => {
    viewDetails()
    getEvent()
  }, [])

  const getEvent = () => {
    GetDataWithToken('getallevent').then(res => {
      if (res?.success) {
        setEventData(res?.data)
      } else {
        toast.error(res?.message)
      }
    })
  }

  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      {loading ? (
        <Loader/>
      ) : (
        <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
          <div className='col-12'>
            <div className='card'>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Employee Type -</label>
                      <span> {data?.emp_type} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Reporting Manager -</label>
                      <span> {data?.Reporting_Manager} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> First Name -</label>
                      <span> {data?.first_name} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Last Name -</label>
                      <span> {data?.last_name} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Email -</label>
                      <span> {data?.email} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Mobile Number -</label>
                      <span> {data?.contact} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>DOB -</label>
                      <span> {data?.dob} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Gender -</label>
                      <span> {data?.gender} </span>
                    </div>
                  </div>
                </div>
                {data?.emp_type === 'employee' && (
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>PAN Card -</label>
                        <span> {data?.pan_card} </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className='employee_section row'>
                  {data?.emp_type === 'employee' && (
                    <div className='col-12 col-md-4'>
                      <div className='employee_details_sec'>
                        <div className='form'>
                          <label for='text'> Aadhar Card -</label>
                          <span> {data?.adhar_card} </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'> Pincode -</label>
                        <span> {data?.pincode} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'> State -</label>
                        <span> {data?.state} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='employee_section row'>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'> Joining Time -</label>
                        <span>
                          {data?.StartTime &&
                            new Date(data.StartTime).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit'
                            })}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Exit Time -</label>
                        <span>
                          {data?.EndTime &&
                            new Date(data.EndTime).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit'
                            })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    
    </Layout>
  )
}

export default EmployeeDetails


import {LOGGED_USER_DETAILS} from '../types'
const initialState = {
  userDetails: {},
  TaskId: ""
};
const userReducers = (state = initialState, action) => {
  switch (action.type) {
    // case LOGGED_USER_DETAILS:
    //   return {
    //     ...state,
    //     userDetails: action.payload,
    //   };
    case LOGGED_USER_DETAILS:
      const { key, value } = action.payload;
      let obj = { ...state.userDetails };
      if (key.includes(".")) {
        let reqKey = key.split(".");
        obj[reqKey[0]][reqKey[1]] = value;
      } else {
        obj[key] = value;
      }
      return {
        ...state,
        userDetails: obj,
      };
    default:
      return state;
  }
};
export default userReducers;


import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";
import { useForm } from "react-hook-form";
import {PostDataWithToken } from "../../constants/ApiHelper";
import {useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
const EditVisitors = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const location = useLocation();
  const [loading,setLoading] = useState(false)

  const handleAddEmp = (data) => {
    setLoading(true)
    PostDataWithToken(`updatevisitors/${location.state.visitorId}`, data).then((res) => { 
      setLoading(false) // Stop loading when the response is received
      if (res?.success) {
        toast.success(res?.message)
        navigate("/visitor")
      } else {
        toast.error(res?.message || "Failed to Update visitor")
      }
    }).catch(err => {
      setLoading(false) // Stop loading in case of an error
      console.error(err)
      toast.error('An error occurred while updating the visitor')
    })
  }
  
  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      {loading ? (
        <Loader/>
      ) : (
        <div className="container-fluid py-4 dash_main">
        <div className="row">
          <div className="col-12">
            <div className="card mb-4 p-3">
              <button
                className="btn btn-outline-danger btn-sm mb-0 ms-auto"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="card-header pb-0">
                  <h6>Add Visitor</h6>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="">
                    <div className="">
                      <div className="card-body">
                        <form onSubmit={handleSubmit(handleAddEmp)}>
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Visitor Name</label>
                              <div className="mb-3">
                                <input
                                  type="taxt"
                                  className="form-control"
                                  placeholder="Visitor Name"
                                  aria-label="name"
                                  aria-describedby="text-addon"
                                  id="name"
                                  defaultValue={location.state.name}
                                  name="name"
                                  {...register("name", {
                                    // required: "Visitor Name is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.name && errors?.name?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* company*/}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label> Company</label>
                              <div className="mb-3">
                                <input
                                  type="taxt"
                                  className="form-control"
                                  placeholder="Visitor Name"
                                  aria-label="company"
                                  aria-describedby="text-addon"
                                  id=" company"
                                  defaultValue={location.state.company}
                                  name=" company"
                                  {...register("company", {
                                    // required: " Company Name is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.company && errors?.company?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* number */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Mobile Number</label>
                              <div className='mb-3'>
                                <input
                                  type='number'
                                  className='form-control'
                                  placeholder='Mobile Number'
                                  aria-label='contact'
                                  aria-describedby='text-addon'
                                  id='contact'
                                  defaultValue={location.state.contact}
                                  pattern='[0-9]{10}'
                                  onInput={e =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  name='contact'
                                  {...register('contact', {
                                    // required: 'Mobile Number is required',
                                    minLength: {
                                      value: 10
                                    }
                                  })}
                                  onChange={e => {
                                    let value = e.target.value.trim()
                                    if (
                                      value.startsWith('0') ||
                                      value.startsWith('1') ||
                                      value.startsWith('2') ||
                                      value.startsWith('3') ||
                                      value.startsWith('4') ||
                                      value.startsWith('5')
                                    ) {
                                      value = value.substring('1')
                                    }
                                    e.target.value = value
                                  }}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.contact &&
                                      errors?.contact?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Visitor Email</label>
                              <div className="mb-3">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Visitor Number"
                                  aria-label="email "
                                  aria-describedby="text-addon"
                                  id="email"
                                  defaultValue={location.state.email}
                                  name="contact"
                                  {...register("email", {
                                    // required: "Visitor Email is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.email && errors?.email?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* visitor category */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Visitor Category</label>
                              <div className='mb-3'>
                                <select
                                  className='form-control'
                                  id='category'
                                  name='category'
                                  defaultValue={location.state?.category || ''}
                                  {...register('category', {
                                    // required: 'Visitor Category Type is required'
                                  })}
                                >
                                  <option key='' value=''>
                                    Select Visitor Category
                                  </option>
                                  <option key='category' value='EXHIBITOR '>
                                  EXHIBITOR 
                                  </option>
                                  <option key='GUEST' value='GUEST'>
                                  GUEST  
                                  </option>
                                  <option key='ORGANISER' value='ORGANISER'>
                                  ORGANISER
                                  </option>
                                  <option key='HOSTED BUYER' value='HOSTED BUYER'>
                                  HOSTED BUYER
                                  </option>
                                  <option key='categoOPERATIONry' value='OPERATION'>
                                  OPERATION
                                  </option>
                                  <option key='TRADE VISTOR' value='TRADE VISTOR'>
                                  TRADE VISTOR
                                  </option>
                                  <option key='SERVICE' value='SERVICE'>
                                  SERVICE
                                  </option>
                                </select>
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.category &&
                                      errors?.category?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/*gender */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Gender</label>
                              <div className='mb-3'>
                                <select
                                  className='form-control'
                                  id='gender'
                                  defaultValue={location.state?.gender || ''}
                                  name='gender'
                                  {...register('gender', {
                                    // required: 'Gender is required'
                                  })}
                                >
                                  <option key='' value=''>
                                    Select Gender
                                  </option>
                                  <option key='male' value='male'>
                                    Male
                                  </option>
                                  <option key='female' value='female'>
                                    Female
                                  </option>
                                  <option key='other' value='other'>
                                    Other
                                  </option>
                                </select>
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.gender && errors?.gender?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* city */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>City</label>
                              <div className="mb-3">
                                <input
                                  type="taxt"
                                  className="form-control"
                                  placeholder="Enter City Name"
                                  aria-label="City"
                                  aria-describedby="text-addon"
                                  id="city"
                                  defaultValue={location.state.city}
                                  name="name"
                                  {...register("city", {
                                    // required: "City Name is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.city && errors?.city?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/*  Ticket/invite/Guestpass*/}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Ticket/invite/Guestpass</label>
                              <div className="mb-3">
                                <input
                                  type="taxt"
                                  className="form-control"
                                  placeholder="Enter Ticket/invite/Guestpass"
                                  aria-label="name"
                                  aria-describedby="text-addon"
                                  id="ticket"
                                  defaultValue={location.state.ticket}
                                  name="ticket"
                                  {...register("ticket", {
                                    // required: "ticket Name is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.ticket && errors?.ticket?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* Hostess */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Hostess</label>
                              <div className="mb-3">
                                <input
                                  type="taxt"
                                  className="form-control"
                                  placeholder="Enter Ticket/invite/Guestpass"
                                  aria-label="hostess"
                                  aria-describedby="text-addon"
                                  id="hostess"
                                  defaultValue={location.state.hostess}
                                  name="hostess"
                                  {...register("hostess", {
                                    // required: "ticket Name is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.hostess && errors?.hostess?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Photo</label>
                              <div className="mb-3">
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter Ticket/invite/Guestpass"
                                  aria-label="hostess"
                                  aria-describedby="text-addon"
                                  id="photo"
                                  name="photo"
                                  {...register("photo", {
                                    // required: "Photo is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.photo && errors?.photo?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            
                            <div className="col-xl-12 col-lg-3 col-md-6 col-12">
                              <label>Professions(Deals In)</label>
                              <div className="mb-3">
                                <input
                                  type="taxt"
                                  className="form-control"
                                  placeholder="Enter Professions(Deals In) "
                                  aria-label="professions"
                                  aria-describedby="text-addon"
                                  id="professions"
                                  defaultValue={location.state.professions}
                                  name="professions"
                                  {...register("professions", {
                                    // required: "Professions Name is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.professions && errors?.professions?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-outline-primary btn-sm mb-0 ms-auto"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}

      {/* </main> */}
    </Layout>
  );
};

export default EditVisitors;

import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useForm } from 'react-hook-form'
import CameraPhoto from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { PostImageDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'
import Loader from '../../common/Loader'
const AddVisitors = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const navigate = useNavigate()
  // const [profilePic, setProfilePic] = useState()
  const [loading, setLoading] = useState(false)
  const [openCamera, setOpenCamera] = useState(false)
  const toggleModal = () => setOpenCamera(!openCamera)

  // const handleUploadFile = e => {
  //   setProfilePic(e?.target?.files[0])
  // }

  const handleAddVisitor = async (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('name', data?.name || '');
    formData.append('company', data?.company || '');
    formData.append('contact', data?.contact || '');
    formData.append('email', data?.email || '');
    formData.append('category', data?.category || '');
    formData.append('gender', data?.gender || '');
    formData.append('city', data?.city || '');
    formData.append('ticket', data?.ticket || '');
    formData.append('hostess', data?.hostess || '');
    formData.append('photo', profilePic || '');
    formData.append('professions', data?.professions || '');
    try {
      const res = await PostImageDataWithToken('createvisitors', formData);
      if (res?.success) {
        toast.success(res?.message);
        navigate('/visitor');
      } else {
        toast.error(res?.message || 'Failed to create visitor');
      }
    } catch (error) {
      toast.error("An error occurred while adding the visitor");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [profilePic, setProfilePic] = useState(null)
  const [showCamera, setShowCamera] = useState(false)
  // Handle file input change
  const handleUploadFile = e => {
    const file = e?.target?.files[0]
    if (file) {
      setProfilePic(file)
      setShowCamera(false) // Ensure camera is hidden if a file is selected
      setOpenCamera(false) // Close modal if open
    }
  }

  // Handle photo capture
  const handleTakePhoto = dataUri => {
    const byteString = atob(dataUri.split(',')[1])
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([ab], { type: mimeString })
    const file = new File([blob], 'captured_photo.png', { type: mimeString })
    setProfilePic(file)
    setShowCamera(false) // Hide camera after capturing photo
    toggleModal(false)
  }

  // Handle button click to open camera if no image is selected
  const handleButtonClick = () => {
    if (!profilePic) {
      setShowCamera(true)
      setOpenCamera(true)
    } else {
      setProfilePic(null)
      setShowCamera(true)
      setOpenCamera(true)
    }
  }

  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      {loading ? (
       <Loader/>
      ) : (
        <>
          <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='card mb-4'>
                <div className='card-header pb-0'>
                  <h6>Add Visitor</h6>
                </div>
                <div className='card-body px-0 pt-0 pb-2'>
                  <div className=''>
                    <div className=''>
                      <div className='card-body'>
                        <form role='form' onSubmit={handleSubmit(handleAddVisitor)}>
                          <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Visitor Name</label>
                              <div className='mb-3'>
                                <input
                                  type='taxt'
                                  className='form-control'
                                  placeholder='Visitor Name'
                                  aria-label='name'
                                  aria-describedby='text-addon'
                                  id='name'
                                  name='name'
                                  {...register('name', {
                                    required: 'Visitor Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.name && errors?.name?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* company*/}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label> Company</label>
                              <div className='mb-3'>
                                <input
                                  type='taxt'
                                  className='form-control'
                                  placeholder='Company'
                                  aria-label='company'
                                  aria-describedby='text-addon'
                                  id=' company'
                                  name=' company'
                                  {...register('company', {
                                    required: ' Company Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.company &&
                                      errors?.company?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* number */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Mobile Number</label>
                              <div className='mb-3'>
                                <input
                                  type='number'
                                  className='form-control'
                                  placeholder='Mobile Number'
                                  aria-label='contact'
                                  aria-describedby='text-addon'
                                  id='contact'
                                  pattern='[0-9]{10}'
                                  onInput={e =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  name='contact'
                                  {...register('contact', {
                                    required: 'Mobile Number is required',
                                    minLength: {
                                      value: 10
                                    }
                                  })}
                                  onChange={e => {
                                    let value = e.target.value.trim()
                                    if (
                                      value.startsWith('0') ||
                                      value.startsWith('1') ||
                                      value.startsWith('2') ||
                                      value.startsWith('3') ||
                                      value.startsWith('4') ||
                                      value.startsWith('5')
                                    ) {
                                      value = value.substring('1')
                                    }
                                    e.target.value = value
                                  }}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.contact &&
                                      errors?.contact?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Visitor Email</label>
                              <div className='mb-3'>
                                <input
                                  type='email'
                                  className='form-control'
                                  placeholder='Visitor Email'
                                  aria-label='email '
                                  aria-describedby='text-addon'
                                  id='email'
                                  name='contact'
                                  {...register('email', {
                                    required: 'Visitor Email is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.email && errors?.email?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* visitor category */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Visitor Category</label>
                              <div className='mb-3'>
                                <select
                                  className='form-control'
                                  id='category'
                                  name='category'
                                  {...register('category', {
                                    required:
                                      'Visitor Category Type is required'
                                  })}
                                >
                                  <option key='' value=''>
                                    Select Visitor Category
                                  </option>
                                  <option key='EXHIBITOR' value='EXHIBITOR'>
                                    EXHIBITOR
                                  </option>
                                  <option key='GUEST' value='GUEST '>
                                    GUEST
                                  </option>
                                  <option key='ORGANISER' value='ORGANISER'>
                                    ORGANISER
                                  </option>
                                  <option
                                    key='HOSTED BUYER'
                                    value='HOSTED BUYER'
                                  >
                                    HOSTED BUYER
                                  </option>
                                  <option key='OPERATION' value='OPERATION'>
                                    OPERATION
                                  </option>
                                  <option
                                    key='TRADE VISTOR'
                                    value='TRADE VISTOR'
                                  >
                                    TRADE VISTOR
                                  </option>
                                  <option key='SERVICE' value='SERVICE'>
                                    SERVICE
                                  </option>
                                </select>
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.category &&
                                      errors?.category?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/*gender */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Gender</label>
                              <div className='mb-3'>
                                <select
                                  className='form-control'
                                  id='gender'
                                  name='gender'
                                  {...register('gender', {
                                    required: 'Gender is required'
                                  })}
                                >
                                  <option key='' value=''>
                                    Select Gender
                                  </option>
                                  <option key='male' value='male'>
                                    Male
                                  </option>
                                  <option key='female' value='female'>
                                    Female
                                  </option>
                                  <option key='other' value='other'>
                                    Other
                                  </option>
                                </select>
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.gender && errors?.gender?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* city */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>City</label>
                              <div className='mb-3'>
                                <input
                                  type='taxt'
                                  className='form-control'
                                  placeholder='Enter City Name'
                                  aria-label='City'
                                  aria-describedby='text-addon'
                                  id='city'
                                  name='name'
                                  {...register('city', {
                                    required: 'City Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.city && errors?.city?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/*  Ticket/invite/Guestpass*/}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Ticket/invite/Guestpass</label>
                              <div className='mb-3'>
                                <input
                                  type='taxt'
                                  className='form-control'
                                  placeholder='Enter Ticket/invite/Guestpass'
                                  aria-label='name'
                                  aria-describedby='text-addon'
                                  id='ticket'
                                  name='ticket'
                                  {...register('ticket', {
                                    required: 'ticket Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.ticket && errors?.ticket?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* Hostess */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Hostess</label>
                              <div className='mb-3'>
                                <input
                                  type='taxt'
                                  className='form-control'
                                  placeholder='Enter Ticket/invite/Guestpass'
                                  aria-label='hostess'
                                  aria-describedby='text-addon'
                                  id='hostess'
                                  name='hostess'
                                  {...register('hostess', {
                                    required: 'ticket Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.hostess &&
                                      errors?.hostess?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Photo</label>
                              <div className='mb-3'>
                                <input
                                  type='file'
                                  className='form-control'
                                  placeholder='Enter Ticket/invite/Guestpass'
                                  aria-label='hostess'
                                  aria-describedby='text-addon'
                                  id='photo'
                                  name='photo'
                                  capture= "environment"
                                  onChange={e => handleUploadFile(e)}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.photo && errors?.photo?.message}
                                  </p>
                                </span>
                              </div>
                            </div> */}
                            <div className='col-xl-9 col-lg-3 col-md-6 col-12 d-flex justify-content-between'>
                              <div className='col-xl-4 col-lg-3 col-md-6 col-12'>
                                <label>Photo</label>
                                <div className='mb-3'>
                                  <input
                                    type='file'
                                    className='form-control'
                                    placeholder='Enter Ticket/invite/Guestpass'
                                    aria-label='hostess'
                                    aria-describedby='text-addon'
                                    id='photo'
                                    name='photo'
                                    onChange={e => handleUploadFile(e)}
                                  />
                                  <span>
                                    <p className='text-danger error-msg'>
                                      {errors?.photo && errors?.photo?.message}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div  style={{marginTop:"30px"}}>
                              <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleButtonClick}
                                  >
                                    Open Camera
                                  </button>
                              </div >
                              <div style={{width:"45%",marginTop:"5px"}}>
                                {profilePic && (
                                  <div>
                                    <h3 className='fs-6'>Selected Image:</h3>
                                    <img
                                      src={URL.createObjectURL(profilePic)}
                                      alt='Selected'
                                      style={{
                                        width: '50%',
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                              <label>Professions(Deals In)</label>
                              <div className='mb-3'>
                                <input
                                  type='taxt'
                                  className='form-control'
                                  placeholder='Enter Professions(Deals In) '
                                  aria-label='professions'
                                  aria-describedby='text-addon'
                                  id='professions'
                                  name='professions'
                                  {...register('professions', {
                                    required: 'Professions Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.professions &&
                                      errors?.professions?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              type='submit'
                              className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openCamera}
        toggle={toggleModal}
        centered
        backdrop={false}
        className='logout_modal'
      >
        <ModalBody>
          <div className='close_btn' onClick={() => toggleModal()}>
            <i className='fa fa-close'></i>
          </div>
          {showCamera && (
            <div style={{ marginTop: '10px' }}>
              <CameraPhoto
                onTakePhoto={handleTakePhoto}
                idealFacingMode={'environment'}
                isImageMirror={false}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
        </>
      )}
    
      {/* </main> */}
    </Layout>
  )
}

export default AddVisitors

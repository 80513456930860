import React, { useEffect, useState } from 'react';
import Layout from '../../common/Layout';
import { GetDataWithToken } from '../../constants/ApiHelper';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import Loader from '../../common/Loader';

const Dashboard = () => {
  const [dasData, setDasData] = useState();
  const [cookies] = useCookies(); // Use useCookies hook to access cookies
 const [loading, setLoading] = useState(false)
 const getDashboardData = () => {
  setLoading(true) // Set loading to true at the start

  GetDataWithToken('dashboard').then(res => {
    setLoading(false) // Stop loading when the response is received
    if (res?.success) {
      setDasData(res?.data)
    } else {
      toast.error(res?.message)
    }
  }).catch(err => {
    setLoading(false) // Stop loading in case of an error
    console.error(err)
    toast.error('An error occurred while fetching dashboard data')
  })
}

  
  useEffect(() => {
    getDashboardData();
  }, []);

  // Determine if the user is admin
  const isAdmin = cookies.userType === "event";

  // Render only Total Visitor section if isAdmin is true
  return (
    <Layout>
      {loading ? (
        <Loader/>
      ) : (
        <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          {isAdmin && (
            <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
              <div className='card'>
                <div className='card-body p-3'>
                  <div className='row'>
                    <div className='col-8'>
                      <div className='numbers'>
                        <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                          Total Visitor
                        </p>
                        <h5 className='font-weight-bolder mb-0'>
                          {dasData?.visitorsCount}
                        </h5>
                      </div>
                    </div>
                    <div className='col-4 text-end'>
                      <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md'>
                        <i className='ni ni-paper-diploma text-lg opacity-10'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Additional sections can be added here */}
          {/* For non-admin, all sections will be shown */}
          {!isAdmin && (
            <>
              <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
                <div className='card dashboard_card'>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className='col-8'>
                        <div className='numbers'>
                          <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                            Total Employee
                          </p>
                          <h5 className='font-weight-bolder mb-0'>
                            {dasData?.employeeCount}
                          </h5>
                        </div>
                      </div>
                      <div className='col-4 text-end'>
                        <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex align-items-center justify-content-center">
                        <img src='./assets/img/user (2).png'alt=''/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
                <div className='card'>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className='col-8'>
                        <div className='numbers'>
                          <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                            Total Volunteer 
                          </p>
                          <h5 className='font-weight-bolder mb-0'>
                            {dasData?.volunteerCount}
                          </h5>
                        </div>
                      </div>
                      <div className='col-4 text-end'>
                        <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex align-items-center justify-content-center">
                        <img src='./assets/img/user (2).png'alt=''/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
              <div className='card'>
                <div className='card-body p-3'>
                  <div className='row'>
                    <div className='col-8'>
                      <div className='numbers'>
                        <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                          Total Visitor
                        </p>
                        <h5 className='font-weight-bolder mb-0'>
                          {dasData?.visitorsCount}
                        </h5>
                      </div>
                    </div>
                    <div className='col-4 text-end'>
                      <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex align-items-center justify-content-center'>
                      <img src='./assets/img/user (2).png'alt=''/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
              <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
                <div className='card'>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className='col-8'>
                        <div className='numbers'>
                          <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                            total Ongoing Events
                          </p>
                          <h5 className='font-weight-bolder mb-0'>
                            {dasData?.ongoingEvents}
                          </h5>
                        </div>
                      </div>
                      <div className='col-4 text-end'>
                        <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex align-items-center justify-content-center'>
                        <img src='./assets/img/user (2).png'alt=''/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-sm-6 mb-xl-0 mb-4'>
                <div className='card'>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className='col-8'>
                        <div className='numbers'>
                          <p className='text-sm mb-0 text-capitalize font-weight-bold'>
                            total upcoming Events
                          </p>
                          <h5 className='font-weight-bolder mb-0'>
                            {dasData?.upcomingEvents}
                          </h5>
                        </div>
                      </div>
                      <div className='col-4 text-end'>
                        <div className='icon icon-shape bg-gradient-primary shadow text-center border-radius-md d-flex align-items-center justify-content-center'>
                          <img src='./assets/img/user (2).png'alt=''/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      )}

    </Layout>
  );
};

export default Dashboard;

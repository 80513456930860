import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'

const EmployeeTaskDetails = () => {
  const location = useLocation()
  const [data, setData] = useState()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)


  const viewDetails = () => {
    setLoading(true)
    GetDataWithToken(`employeetask/${location?.state?.id}`).then(res => {
      setLoading(false) // Stop loading when the response is received
      if (res?.success) {
        setData(res?.data)
      } else {
        toast.error(res?.message)
      }
    }).catch(err => {
      setLoading(false) // Stop loading in case of an error
      console.error(err)
      toast.error('An error occurred while fetching the details')
    })
  }
  

  useEffect(() => {
    viewDetails()
  }, [])

  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      {loading ? (
        <Loader/>
      ) : (
        <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className='card'>
            <div className='row'>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Employee id -</label>{' '}
                      <span> {data?.empid} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Employee Name -</label>
                      <span> {data?.empname} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Event Id-</label>
                      <span> {data?.eventid} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Event Name -</label>
                      <span> {data?.eventname} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Task Location -</label>
                      <span> {data?.taskLocation} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Task Start Time -</label>
                      <span>
                        {data?.taskStartTime &&
                          new Date(data.taskStartTime).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Task End Time -</label>
                      <span>
                        {data?.taskEndTime &&
                          new Date(data?.taskEndTime).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
  
    </Layout>
  )
}

export default EmployeeTaskDetails

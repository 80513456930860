import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useForm } from 'react-hook-form'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const EditOrganization = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" }); 
  // const [orgData, setOrgData] = useState([]);
  const navigate = useNavigate();
 const location = useLocation()
  // useEffect(() => {
  //   GetDataWithToken("getallorganisation").then((res) => {
  //     if (res?.success) {
  //           setOrgData(res?.data);
  //     } else {
  //       console.log("Error", res?.message);
  //     }
  //   });
  // }, []);


  const handleEditOrg = data => {
    PostDataWithToken(`organisationupdate/${location?.state?.id}`, data).then(
      res => {
        if (res?.success) {
          navigate('/organization')
          toast.success(res?.message)
        } else {
          toast.error(res?.message)
        }
      }
    )
  }
  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="card-header pb-0">
                  <h6>Edit Organisation</h6>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="">
                    <div className="">
                      <div className="card-body">
                        <form role="form" onSubmit={handleSubmit(handleEditOrg)}>
                          <div className="row">
                                {/* org type */}
                                <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Organisation Type</label>
                              <div className="mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Org Type"
                                  aria-label="org_type"
                                  aria-describedby="text-addon"
                                  id="org_type"
                                  defaultValue={location.state.org_type}
                                  name="org_type"
                                  {...register("org_type", {
                                    required: "Org type is required",
                                  })}
                                />
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.org_type && errors?.org_type?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Name</label>
                              <div className="mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  aria-label=" Name"
                                  aria-describedby="text-addon"
                                  id="name"
                                  defaultValue={location.state.name}
                                  name='name'
                                  {...register('name', {
                                    required: ' Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.name && errors?.name?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* Last Name */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Address</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='address'
                                  aria-label='address'
                                  aria-describedby='text-addon'
                                  id='address'
                                  defaultValue={location.state.address}
                                  name='address'
                                  {...register('address', {
                                    required: 'Address is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.address &&
                                      errors?.address?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}
                            {/* Pincode */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Pincode</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Pincode'
                                  aria-label='Pincode'
                                  aria-describedby='text-addon'
                                  id='pincode'
                                  defaultValue={location.state.pincode}
                                  name='pincode'
                                  {...register('pincode', {
                                    required: 'Pincode is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.pincode &&
                                      errors?.pincode?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* State */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>State</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='State'
                                  aria-label='State'
                                  aria-describedby='text-addon'
                                  id='state'
                                  defaultValue={location.state.state}
                                  name='state'
                                  {...register('state', {
                                    required: 'State is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.state && errors?.state?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* City */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>City</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='City'
                                  aria-label='City'
                                  aria-describedby='text-addon'
                                  id='city'
                                  defaultValue={location.state.city}
                                  name='city'
                                  {...register('city', {
                                    required: 'City is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.city && errors?.city?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* gstin*/}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>GSTIN</label>
                              <div className="mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="GSTIN"
                                  aria-label="GSIJN"
                                  aria-describedby="text-addon"
                                  id="GSIJN"
                                  maxLength={"15"}
                                  defaultValue={location.state.GSIJN}
                                  name="GSIJN"
                                  {...register("GSIJN", {
                                    pattern: {
                                      value: /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$/,
                                      message: "Invalid GSTIN Number",                                    
                                    },
                                    
                                    required: "GSTIN is required",
                                  })}
                                  style={{textTransform:"uppercase"}}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.GSIJN && errors?.GSIJN?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* cin */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>CIN</label>
                              <div className="mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="CIN"
                                  aria-label="CIN"
                                  aria-describedby="text-addon"
                                  maxLength={21}
                                  id="CIN"
                                  defaultValue={location.state.CIN}
                                  name='CIN'
                                  {...register('CIN', {
                                    required: 'CIN is required'
                                  })}
                                  style={{textTransform:"uppercase"}}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.CIN && errors?.CIN?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              type='submit'
                              className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </main> */}
    </Layout>
  )
}

export default EditOrganization

import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import {useNavigate } from 'react-router-dom'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import Pagination from '../../common/Pagination'
import Loader from '../../common/Loader'

const Employee = () => {
  const navigate = useNavigate()
  const [empData, setEmpData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const toggleDeleteModal = () => setDeleteModal(!deleteModal)
  const [empId, setEmpId] = useState('')
  const [loading, setLoading] = useState(false)

  // Pagination Start
  const [pageCount, setPageCount] = useState()
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [itemOffset, setItemOffset] = useState(0)

  const handlePageClick = event => {
    const newOffset = event.selected * itemsPerPage
    setItemOffset(newOffset)
  }

  const endOffset = itemOffset + itemsPerPage
  const currentItems = empData.slice(itemOffset, endOffset)
  const handleChangeItemPerPage = (e) => {
    setItemsPerPage(e);
  };
  // Pagination End

  useEffect(() => {
    getEmployees()
  }, [])
  const getEmployees = () => {
    setLoading(true)
    GetDataWithToken('getAllEmployee').then(res => {
      setLoading(false) // Stop loading when the response is received
      if (res?.success) {
        setEmpData(res?.data)
        setPageCount(Math.ceil(res?.data.length / itemsPerPage))
      } else {
        toast.error(res?.message)
      }
    }).catch(err => {
      setLoading(false) // Stop loading in case of an error
      console.error(err)
      toast.error('An error occurred while fetching employee data')
    })
  }
  


  const handleDelete = id => {
    PostDataWithToken(`deleteemployee/${id}`).then(res => {
      if (res?.success) {
        toast.success(res?.message)
        getEmployees()
        toggleDeleteModal()
      } else {
        toast.error(res?.message)
      }
    })
  }

  return (
    <Layout>
      {/* <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg "> */}
      {loading ? (
        <Loader/>
      ) : (
        <>
         <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                onClick={() => navigate('/add-employee')}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4'>
              <div className='card-header pb-0'>
                <h6>Employees</h6>
              </div>
              <div className='card-body pt-0 pb-2'>
                <div className='table-responsive p-0'>
                  <table className='table align-items-center mb-0'>
                    <thead>
                      <tr>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Name
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Email
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Mobile
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Type
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          create Time
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Status
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Action
                        </th>
                        {/* <th className='text-secondary opacity-7'>Action</th> */}
                    </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <h6 className='mb-0 text-sm'>
                                {item?.first_name + ' ' + item?.last_name}
                              </h6>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.email}
                              </p>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.contact}
                              </p>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.emp_type}
                              </p>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                              {item?.createdAt &&
                          new Date(item.createdAt).toLocaleString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                       })}
                              </p>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.status}
                              </p>
                            </td>
                            <td className='align-middle'>
                              <div
                                className='text-secondary font-weight-bold text-xs'
                                data-toggle='tooltip'
                                data-original-title='Edit user'
                              >
                                <div className='d-flex gap-4'>
                                  <div
                                    onClick={() =>
                                      navigate('/view-employee', {
                                        state: item
                                      })
                                    }
                                  >
                                    <img src='.\assets\img\info.png' style={{width:'20px'}} alt=''/>
                                  </div>
                                  <div
                                    onClick={() =>
                                      navigate('/edit-employee', {
                                        state: item
                                      })
                                    }
                                  >
                                   <img  src='.\assets\img\pencil.png' style={{width:'15px'}} alt=''/>
                                  </div>
                                  <div
                                    onClick={() => {
                                      toggleDeleteModal()
                                      setEmpId(item?.id)
                                    }}
                                  >
                                    <img src='.\assets\img\delete.png' style={{width:'15px'}} alt=''/>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                <div>
                <div>
                <p>Showing 1 - {itemsPerPage} items of {empData?.length}</p>
              </div>
                </div>
                <div  className='main-page-item'>
    
                  <div className='show-page'>
                <select className="form-control" onClick={(e) => handleChangeItemPerPage(e?.target?.value)}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>

                  <div>
                  <ReactPaginate
                breakLabel='...'
                nextLabel='>'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel='<'
                renderOnZeroPageCount={null}
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
              />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        centered
        backdrop={false}
        className="logout_modal"
      >
        <ModalBody>
          <h3 className='fs-6'>Are you sure you want to delete this employee!!</h3>
          <div className='modal_btn'>
            <button
              className='btn btn-danger'
              onClick={() => toggleDeleteModal()}
            >
              Cancel
            </button>
            <button
              className='btn btn-success'
              onClick={() => handleDelete(empId)}
            >
              Confirm
            </button>
          </div>
        </ModalBody>
      </Modal>
        </>
      )}
     
      {/* <Footer /> */}
      {/* </main> */}
    </Layout>
  )
}

export default Employee

import React from 'react'

const Loader = () => {
  return (
    <>
      <div className='full-page-loader'>
      <div class="loader">
  <div class="loader-wheel"></div>
  <div class="loader-text"></div>
</div>
  </div>
    </>
  )
}

export default Loader
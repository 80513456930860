import React, { useEffect, useState, useRef } from 'react'
import Layout from '../../common/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { PDFDocument } from 'pdf-lib';
import VisitorsDetails from './VisitorsDetails'
import Loader from '../../common/Loader'

const Visitors = () => {
  const navigate = useNavigate()
  const { eventId } = useParams();
  const [visitorData, setVisitorData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [details, setDetails] = useState(false)
  const toggleDeleteModal = () => setDeleteModal(!deleteModal)
  const toggleDetailsModal = () => setDetails(!details)
  const [visitorId, setVisitorId] = useState('')
 const [ getEventVisitor,  setGetEventVisitor] = useState('')
  const [selectedVisitor, setSelectedVisitor] = useState(null)
  const [ loading, setLoading] = useState(false)
  const cardRef = useRef(null)

  useEffect(() => {
    // if(eventId) {
    //   getVisitor()
    // }else{
    //   getAllVisitor()
    // }
    getAllVisitor()
  }, [])

  const getAllVisitor = () => {
    setLoading(true)
    GetDataWithToken('getAllVisitors').then(res => {
      setLoading(false) // Stop loading when the response is received
      if (res?.success) {
        setVisitorData(res?.data)
        setPageCount(Math.ceil(res?.data.length / itemsPerPage))
      } else {
        toast.error(res?.message)
      }
    }).catch(error => {
      setLoading(false) // Stop loading in case of an error
      toast.error('An error occurred while fetching visitors')
    })
  }
  

  // const getVisitor = () => {
  //   GetDataWithToken(`visitor/${eventId}`).then(res => {
  //     if (res?.success) {
  //       setVisitorData(res?.data)
  //       setPageCount(Math.ceil(res?.data.length / itemsPerPage))
  //     } else {
  //       toast.error(res?.message)
  //     }
  //   })
  // }

  const handleDelete = id => {
    setLoading(true)
    PostDataWithToken(`deletevisitors/${id}`).then(res => {
      setLoading(false) // Stop loading when the response is received
      if (res?.success) {
        toast.success(res?.message)
        getAllVisitor()
        toggleDeleteModal()
      } else {
        toast.error(res?.message)
      }
    }).catch(error => {
      setLoading(false) // Stop loading in case of an error
      toast.error('An error occurred while deleting the visitor')
    })
  }
  
  const visitorBarcoad = visitor => { 
    GetDataWithToken(`visitor-info/${visitor?.visitorId}`).then(res => {
      console.log("Barcode Image Response:", res);
      if (res?.success) {
        setSelectedVisitor({
          ...visitor,
          barcodeUrl: res.visitor.barcodeUrl
        });
      }
    });
  };

  const handlePrintClick = visitor => {
    setSelectedVisitor(visitor)
    visitorBarcoad(visitor)
    toggleDetailsModal()
  }

//print card 
const downloadPDF = async () => {
  const cardElement = cardRef.current;
  const canvas = await html2canvas(cardElement, {
    useCORS: true,
    allowTaint: true,
    scale: 3
  });
  const imgData = canvas.toDataURL('image/png');
  console.log('image--', imgData);
  const pdfDoc = await PDFDocument.create();
  const pdfWidth = canvas.width;
  const pdfHeight = canvas.height;
  const page = pdfDoc.addPage([pdfWidth, pdfHeight]);
  const pngImage = await pdfDoc.embedPng(imgData);
  page.drawImage(pngImage, {
    x: 0,
    y: 0,
    width: pdfWidth,
    height: pdfHeight
  });
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'card.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

  // Pagination Start
  const [pageCount, setPageCount] = useState()
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [itemOffset, setItemOffset] = useState(0)

  const handlePageClick = visitor => {
    const newOffset = visitor.selected * itemsPerPage
    setItemOffset(newOffset)
  }

  const endOffset = itemOffset + itemsPerPage
  const currentItems = visitorData.slice(itemOffset, endOffset)
  const handleChangeItemPerPage = e => {
    setItemsPerPage(e)
  }
  // Pagination End

  return (
    <Layout>
      {loading ? (
       <Loader/>
      ) : (
 <>
 <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <div className='d-flex justify-content-between'>
                <div>
                  <button
                    className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                    onClick={() => navigate('/scan')}
                  >
                    Scan
                  </button>
                </div>
                <div>
                  <button
                    className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                    onClick={() => navigate('/add-visitor')}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4'>
              <div className='card-header pb-0'>
                <h6>Visitors</h6>
              </div>
              <div className='card-body pt-0 pb-2'>
                <div className='table-responsive p-0'>
                  <table className='table align-items-center mb-0'>
                    <thead>
                      <tr>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Visitor ID
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Visitor Name
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Visitor Category
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Visitor Number
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Action
                        </th>
                        <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                          Id Card
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.visitorId}
                              </p>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.name}
                              </p>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.category}
                              </p>
                            </td>
                            <td>
                              <p className='text-xs font-weight-bold mb-0'>
                                {item?.contact}
                              </p>
                            </td>
                            <td className='align-middle'>
                              <div
                                className='text-secondary font-weight-bold text-xs'
                                data-toggle='tooltip'
                                data-original-title='Edit user'
                              >
                                <div className='d-flex gap-4'>
                                  <div
                                    onClick={() =>
                                      navigate('/view-visitor', {
                                        state: item
                                      })
                                    }
                                  >
                                     <img src='.\assets\img\info.png' style={{width:'20px', color:'red'}} alt=''/>
                  
                                  </div>
                                  <div
                                    onClick={() =>
                                      navigate('/edit-visitor', {
                                        state: item
                                      })
                                    }
                                  >
                                        <img  src='.\assets\img\pencil.png' style={{width:'15px'}} alt=''/>
                                  </div>
                                  <div
                                    onClick={() => {
                                      toggleDeleteModal()
                                      setVisitorId(item?.id)
                                    }}
                                  >
                                    <img src='.\assets\img\delete.png' style={{width:'15px'}} alt=''/>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <dvi className="d-flex  gap-3">
                              <div onClick={() => handlePrintClick(item)} >
                                <img src='.\assets\img\eye.png' alt=''/>
                              </div>

                              </dvi>
                            
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <div className='d-flex align-items-center justify-content-between flex-wrap'>
                <div>
                  <p>
                    Showing 1 - {itemsPerPage} items of {visitorData?.length}
                  </p>
                </div>
                <div className='main-page-item'>
                  <div className='show-page'>
                    <select
                      className='form-control'
                      onClick={e => handleChangeItemPerPage(e?.target?.value)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                  <div>
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel='<'
                      renderOnZeroPageCount={null}
                      pageClassName='page-item'
                      pageLinkClassName='page-link'
                      previousClassName='page-item'
                      previousLinkClassName='page-link'
                      nextClassName='page-item'
                      nextLinkClassName='page-link'
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        centered
        backdrop={false}
        className='logout_modal'
      >
        <ModalBody>
          <p>Are you sure you want to delete this Visitor</p>
          <div className='modal_btn'>
            <button
              className='btn btn-danger'
              onClick={() => toggleDeleteModal()}
            >
              Cancel
            </button>
            <button
              className='btn btn-success'
              onClick={() => handleDelete(visitorId)}
            >
              Confirm
            </button>
          </div>
        </ModalBody>
      </Modal>

      {selectedVisitor && (
        <Modal
          isOpen={details}
          toggle={toggleDetailsModal}
          centered
          backdrop={false}
          className='logout_modal'
        >
          <ModalBody>
            <div className='close_btn' onClick={() => toggleDetailsModal()}>
              <i className='fa fa-close'></i>
            </div>
            <div ref={cardRef}>
              <div class='id-card'>
                <div class='id-header'>
                  <img
                    class='id-photo'
                    src={selectedVisitor?.photo}
                    crossorigin='anonymous'
                    alt='img'
                  />
                </div>
                <div class='id-details'>
                  <h1 class='m-name'>{selectedVisitor?.name}</h1>
                  <div class='m-info'>
                    <div>
                      <div class='m-info-group d-flex justify-content-center algin-items-center'>
                        <h5 class='info-label d-flex '>
                          Visitor id:-
                          <h6 class='info-value'> {selectedVisitor?.visitorId}</h6>
                        </h5>
                      </div>
                      <div class='m-info-group d-flex align-items-center '>
                        <h5 class='info-label'>Phone: </h5>
                        <h6 class='info-value'> {selectedVisitor?.contact}</h6>
                      </div>
                      <div class='m-info-group d-flex gap-2'>
                        <h5 class='info-label'>Email:</h5>
                        <h6 class='info-value email'>
                          {' '}
                          {selectedVisitor?.email}
                        </h6>
                      </div>
                      <div class='m-qrcode'>
                      <img src={selectedVisitor?.barcodeUrl} alt='qr-code'/>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="id-footer">
        www.website.com
    </div> */}
              </div>
            </div>
            <div className='modal_btn mt-3'>
              <button className='btn btn-primary' onClick={downloadPDF}>
                Download Card
              </button>
              <button
                className='btn btn-secondary'
                onClick={() => window.print()}
              >
                Print Card
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
 </>
      )}
      
    </Layout>
  )
}

export default Visitors

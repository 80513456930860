import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import CardPDF from '../../common/CardPDF'
import { useReactToPrint } from 'react-to-print'

const CardContainer = React.forwardRef(() => {
  const [profileData, setProfileData] = useState({})
  const componentRef = React.useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <div>
      <CardPDF
        ref={componentRef}
        name={'Mohit'}
        description={'Guest'}
        imageUrl={'fasdfdasfasdf.com'}
      />
      <button onClick={handlePrint}>Print</button>
    </div>
  )
})

export default CardContainer

import React, { useState } from 'react'
import LoginHeader from '../../common/LoginHeader'
import Loginfooter from '../../common/Loginfooter'
import { useForm } from 'react-hook-form'
import { PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router'
import Loader from '../../common/Loader'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { dispatchUser } from '../../store/actions/userActions'
import { toast } from 'react-toastify'
import Loader_login from '../../common/Loader_login'

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const cookie = useCookies()
  const [cookies, setCookie, removeCookie] = useCookies([
    'name',
    'taskId',
    'firstName',
    'lastName',
    'userName',
    'token'
  ])

  const handleLogin = data => {
    setLoading(true)
    PostDataWithToken('login', data).then(res => {
      console.log(res, 'rytewfgrhe')
      if (res?.success) {
        if (res?.userType === 'admin') {
          dispatchUser('Username', res?.data?.username)
          dispatchUser('UserType', res?.userType)
          document.cookie = `token=${res?.token}; domain=.registration4u.in`
          document.cookie = `username=${res?.data?.username}; domain=.registration4u.in`
          document.cookie = `userType=${res?.userType}; domain=.registration4u.in`
          removeCookie('firstName', { path: '/' })
          removeCookie('lastName', { path: '/' })
          removeCookie('taskId', { path: '/' })
          removeCookie('email', { path: '/' })
          toast.success("login SuccessFul")
        } else {
          dispatchUser('UserType', res?.userType)
          dispatchUser('Email', res?.data?.employee?.email)
          dispatchUser('FirstName', res?.data?.employee?.first_name)
          dispatchUser('LastName', res?.employee?.last_name)
          dispatchUser('TaskId', res?.emptask?.id)

          document.cookie = `userType=${res?.userType}; domain=.registration4u.in`
          document.cookie = `token=${res?.token}; domain=.registration4u.in`
          document.cookie = `email=${res?.data?.employee?.email}; domain=.registration4u.in`
          document.cookie = `firstName=${res?.data?.employee?.first_name}; domain=.registration4u.in`
          document.cookie = `lastName=${res?.data?.employee?.last_name}; domain=.registration4u.in`
          document.cookie = `taskId=${res?.data?.emptask?.id}; domain=.registration4u.in`
          removeCookie('username', { path: '/' })
        }

        navigate('/dashboard')
        setLoading(false)
      }
     else {
      toast.error("  Incorrect Password Please enter a valid password");
    }
    })
  }

  return (
    <div>
      {/* <LoginHeader /> */}
      <main className='logout  mt-0 ms-0' style={{ overflow: 'hidden' }}>
        <section>
          <div className='page-header min-vh-75'>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto'>
                  <div className='card card-plain mt-4'>
                    <div className='card-header pb-0 text-left bg-transparent'>
                      <Link to=''>
                        <img
                          src='./assets/img/r4u_logo.png'
                          style={{ maxWidth: '120px' }}
                          alt=''
                        />
                      </Link>
                      <h3 className='font-weight-bolder text-info text-gradient'>
                        Welcome back
                      </h3>
                      <p className='mb-0'>
                        Enter your username and password to sign in
                      </p>
                    </div>

                    <div className='card-body'>
                      <form role='form' onSubmit={handleSubmit(handleLogin)}>
                        <label>Username</label>
                        <div className='mb-3'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Username'
                            aria-label='Username'
                            aria-describedby='email-addon'
                            id='username'
                            name='username'
                            {...register('username', {
                              required: 'Username is required'
                            })}
                          />
                          <span>
                            <p className='text-danger error-msg'>
                              {errors.username && errors?.username?.message}
                            </p>
                          </span>
                        </div>
                        <label>Password</label>
                        <div className='mb-3 position-relative'>
                          <input
                            type={showPass === false ? 'password' : ''}
                            className='form-control position-relative pe-6'
                            placeholder='Password'
                            aria-label='Password'
                            aria-describedby='password-addon'
                            id='password'
                            name='password'
                            {...register('password', {
                              required: 'Password is required'
                            })}
                          />
                          <span>
                            <p className='text-danger error-msg'>
                              {errors?.password && errors?.password?.message}
                            </p>
                          </span>
                          {showPass === false ? (
                            <span
                              className='eye_icon'
                              style={{ cursor: 'pointer' }}
                              onClick={() => setShowPass(true)}
                            >
                              <i className='fa fa-eye'></i>
                            </span>
                          ) : (
                            <span
                              className='eye_icon'
                              style={{ cursor: 'pointer' }}
                              onClick={() => setShowPass(false)}
                            >
                              <i className='fa fa-eye-slash'></i>
                            </span>
                          )}
                        </div>
                        {/* <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="rememberMe"
                            checked=""
                          />
                          <label className="form-check-label" for="rememberMe">
                            Remember me
                          </label>
                        </div> */}
                        <div className='text-center'>
                          <button
                            type='submit'
                            className='btn bg-gradient-info w-100 mt-4 mb-0'
                          >
                            {loading ? <Loader_login/> : 'Sign In'}
                          </button>
                        </div>
                      </form>
                    </div>

                    {/* <div className="card-footer text-center pt-0 px-lg-2 px-1">
                      <p className="mb-4 text-sm mx-auto">
                        Don't have an account?
                        <a
                          href="javascript:;"
                          className="text-info text-gradient font-weight-bold"
                        >
                          Sign up
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='oblique position-absolute top-0 h-100 d-md-block d-none me-n8'>
                    <div
                      className='oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6'
                      // style="background-image:url('../assets/img/curved-images/curved6.jpg')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Loginfooter />
      </main>
      {/* <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- --> */}
    </div>
  )
}

export default Login

import {
  LOGGED_USER_DETAILS
} from "../types";
import store from "../index";

export const saveUserDetails = (payload) => {
  return {
    type: LOGGED_USER_DETAILS,
    payload,
  };
};


export const dispatchUser = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(saveUserDetails(obj));
};



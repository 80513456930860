import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useForm } from 'react-hook-form'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const AddOrganization = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const [orgData, setOrgData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    GetDataWithToken('getallcategory').then(res => {
      if (res?.success) {
        setOrgData(res?.data)
      } else {
        console.log('Error', res?.message)
      }
    })
 
  }, [])

  const handleAddOrg = data => {
    PostDataWithToken('createorganisation', data).then(res => {
      if (res?.success) {
        toast.success(res?.message)
        navigate('/organization')
      } else {
        toast.error(res?.message)
      }
    })
  }
  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className=''>
            <div className='col-12'>
              <div className='card mb-4'>
                <div className='card-header pb-0'>
                  <h6>Add Organisation</h6>
                </div>

                <div className='card-body px-0 pt-0 pb-2'>
                  <div className=''>
                    <div className=''>
                      <div className='card-body'>
                        <form role='form' onSubmit={handleSubmit(handleAddOrg)}>
                          <div className='row'>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                              <label>Organisation category</label>
                              <div className="mb-3">
                                <select
                                  className="form-control"
                                  id="orgCategory"
                                  name="orgCategory"
                                  {...register("orgCategory", {
                                    required: "Organisation is required",
                                  })}
                                >
                                  <option key="" value="">
                                    Select Organisation category
                                  </option>
                                  {orgData?.map((item, index) => (
                                    <option key={index} value={item?.id}>
                                      {item?.Orgcategory}
                                    </option>
                                  ))}
                                </select>
                                <span>
                                  <p className="text-danger error-msg">
                                    {errors?.orgCategory && errors?.orgCategory?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Organization Name</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Name'
                                  aria-label=' Name'
                                  aria-describedby='text-addon'
                                  id='name'
                                  name='name'
                                  {...register('name', {
                                    required: ' Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.name && errors?.name?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* Last Name */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Address</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Address'
                                  aria-label='address'
                                  aria-describedby='text-addon'
                                  id='address'
                                  name='address'
                                  {...register('address', {
                                    required: 'Address is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.address &&
                                      errors?.address?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}
                            {/* Pincode */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Pincode</label>
                              <div className='mb-3'>
                                <input
                                  type='number'
                                  className='form-control'
                                  placeholder='Pincode'
                                  aria-label='Pincode'
                                  onInput={e =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      6
                                    ))
                                  }
                                  aria-describedby='text-addon'
                                  id='pincode'
                                  name='pincode'
                                  {...register('pincode', {
                                    required: 'Pincode is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.pincode &&
                                      errors?.pincode?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* State */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>State</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='State'
                                  aria-label='State'
                                  aria-describedby='text-addon'
                                  id='state'
                                  name='state'
                                  {...register('state', {
                                    required: 'State is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.state && errors?.state?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* City */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>City</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='City'
                                  aria-label='City'
                                  aria-describedby='text-addon'
                                  id='city'
                                  name='city'
                                  {...register('city', {
                                    required: 'City is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.city && errors?.city?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* Start Time */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>GSTIN</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='GSTIN'
                                  aria-label='GSIJN'
                                  maxLength={15}
                                  aria-describedby='text-addon'
                                  id='GSIJN'
                                  name='GSIJN'
                                  {...register('GSIJN', {
                                    pattern: {
                                      value:
                                        /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$/,
                                      message: 'Invalid GSTIN Number'
                                    },
                                    required: 'GSTIN is required'
                                  })}
                                  style={{ textTransform: 'uppercase' }}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.GSIJN && errors?.GSIJN?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* End Time */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>CIN</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='CIN'
                                  aria-label='CIN'
                                  maxLength={21}
                                  aria-describedby='text-addon'
                                  id='CIN'
                                  name='CIN'
                                  {...register('CIN', {
                                    required: 'CIN is required'
                                  })}
                                  style={{ textTransform: 'uppercase' }}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.CIN && errors?.CIN?.message}
                                  </p>
                                </span>
                              </div>
                            </div>

                            {/* End Time */}
                          </div>
                          <div>
                            <button
                              type='submit'
                              className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </main> */}
    </Layout>
  )
}

export default AddOrganization

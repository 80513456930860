export const sideBarArr = [
    {
        pathname: ['/dashboard'],
        path: '/dashboard',
        icon: <img src=".\assets\img\dashboard (1).png"/>,
        name: 'Dashboard'
    },
    {
        pathname: ['/employee','/add-employee', '/edit-employee', '/view-employee'],
        path: '/employee',
        icon: <img src="./assets/img/user (1).png"/>,
        name: 'Employees'
    },
    {
        pathname: ['/event', '/add-event', '/edit-event','/view-event'],
        path: '/event',
        icon: <img src="./assets/img/user (1).png"/>,
        name: 'Events'
    },
    {
        pathname: ['/employe-task','/add-employe-task','/edit-employe-task','/view-employe-task'],
        path: '/employe-task',
        icon: <img src="./assets/img/user (1).png"/>,
        name: 'Employee Task'
    },
    {
        pathname: ['/visitor','/add-visitor','/edit-visitor','/view-visitor','/view-card'],
        path: '/visitor',
        icon: <img src="./assets/img/user (1).png"/>,
        name: 'Visitors'
    },
];

export const sideBarArrAdmin = [
    {
        pathname: ['/dashboard'],
        path: '/dashboard',
        icon: <i className='fa fa-dashboard'></i>,
        name: 'Dashboard'
    },
    {
        pathname: ['/visitor','/add-visitor','/edit-visitor','/view-visitor','/view-card'],
        path: '/visitor',
        icon: <i className='fa fa-calendar'></i>,
        name: 'Visitors'
    },
];

import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  profileBanner: {
    backgroundColor: 'lightblue',
    padding: 10,
    marginBottom: 10
  },
  profileThumb: {
    marginBottom: 10
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50
  },
  profileName: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5
  },
  profileDescription: {
    fontSize: 12
  }
})

// Create Card component
const CardPDF = ({ name, description, imageUrl }) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.section}>
        <View style={styles.profileBanner}>
          <View style={styles.profileThumb}>
            <Image src={imageUrl} style={styles.profileImage} />
          </View>
        </View>
        <Text style={styles.profileName}>{name}</Text>
        <Text style={styles.profileDescription}>{description}</Text>
      </View>
    </Page>
  </Document>
)

export default CardPDF

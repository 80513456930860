import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useForm } from 'react-hook-form'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'
const AddEmployeeTask = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const [emData, setEmData] = useState([])
  const [evData, setEvData] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    GetDataWithToken('getAllEmployee').then(res => {
      if (res?.success) {
        setEmData(res?.data)
      } else {
        console.log('Error', res?.message)
      }
    })
    GetDataWithToken('getallevent').then(res => {
      if (res?.success) {
        setEvData(res?.data)
      } else {
        console.log('Error', res?.message)
      }
    })
  }, [])

  const handleUpdataEmpTask = data => {
    setLoading(true) // Set loading to true at the start
    PostDataWithToken(`updateemptask/${location?.state?.id}`, data)
      .then(res => {
        setLoading(false) // Stop loading when the response is received
        if (res?.success) {
          navigate('/employe-task')
          toast.success(res?.message)
        } else {
          toast.error(res?.message)
        }
      })
      .catch(err => {
        setLoading(false) // Stop loading in case of an error
        console.error(err)
        toast.error('An error occurred while updating the employee task')
      })
  }

  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      {loading ? (
        <Loader />
      ) : (
        <div className='container-fluid py-4 dash_main'>
          <div className='row'>
            <div className='col-12'>
              <div className='card mb-4 p-3'>
                <button
                  className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='card mb-4'>
                  <div className='card-header pb-0'>
                    <h6>Add Employee Task</h6>
                  </div>
                  <div className='card-body px-0 pt-0 pb-2'>
                    <div className=''>
                      <div className=''>
                        <div className='card-body'>
                          <form onSubmit={handleSubmit(handleUpdataEmpTask)}>
                            <div className='row'>
                              {/* Employee Type */}
                              {/* Reporting Manager */}
                              <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                                <label>Employee Id</label>
                                <div className='mb-3'>
                                  <select
                                    className='form-control'
                                    id='empid'
                                    name='empid'
                                    {...register('empid', {
                                      // required: 'Reporting Manager is required'
                                    })}
                                  >
                                    <option key='' value=''>
                                      select Employe
                                    </option>
                                    {emData?.map((item, index) => (
                                      <option key={index} value={item?.id}>
                                        {item?.first_name +
                                          ' ' +
                                          item?.last_name}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <p className='text-danger error-msg'>
                                      {errors?.empid && errors?.empid?.message}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                                <label>Event Id</label>
                                <div className='mb-3'>
                                  <select
                                    className='form-control'
                                    id='eventid'
                                    defaultValue={location.state.eventid}
                                    name='eventid'
                                    {...register('eventid', {
                                      // required: 'Reporting Manager is required'
                                    })}
                                  >
                                    <option key='' value=''>
                                      select Employe
                                    </option>
                                    {evData?.map((item, index) => (
                                      <option key={index} value={item?.id}>
                                        {item?.eventName}
                                      </option>
                                    ))}
                                  </select>
                                  <span>
                                    <p className='text-danger error-msg'>
                                      {errors?.eventid &&
                                        errors?.eventid?.message}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              {/* Start Time */}
                              <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                                <label>Start Time</label>
                                <div className='mb-3'>
                                  <input
                                    type='date'
                                    className='form-control'
                                    placeholder='Start Time'
                                    aria-label='task_start_time'
                                    aria-describedby='text-addon'
                                    id='task_start_time'
                                    defaultValue={
                                      location.state.task_start_time
                                    }
                                    name='task_start_time'
                                    {...register('task_start_time', {
                                      // required: 'Start Time is required'
                                    })}
                                  />
                                  <span>
                                    <p className='text-danger error-msg'>
                                      {errors?.task_start_time &&
                                        errors?.task_start_time?.message}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              {/* </div> */}

                              {/* End Time */}
                              <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                                <label>End Time</label>
                                <div className='mb-3'>
                                  <input
                                    type='datetime-local'
                                    className='form-control'
                                    placeholder='End Time'
                                    aria-label='End Time'
                                    aria-describedby='text-addon'
                                    id='task_end_time'
                                    defaultValue={location.state.task_end_time}
                                    name='task_end_time'
                                    {...register('task_end_time', {
                                      // required: 'End Time is required'
                                    })}
                                  />
                                  <span>
                                    <p className='text-danger error-msg'>
                                      {errors?.task_end_time &&
                                        errors?.task_end_time?.message}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              {/* City */}
                              <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                                <label>Task Category</label>
                                <div className='mb-3'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Catrgory'
                                    aria-label='City'
                                    aria-describedby='text-addon'
                                    id='task_category'
                                    defaultValue={location.state.task_category}
                                    name='task_category'
                                    {...register('task_category', {
                                      // required: 'City is required'
                                    })}
                                  />
                                  <span>
                                    <p className='text-danger error-msg'>
                                      {errors?.task_category &&
                                        errors?.task_category?.message}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                                <label>Task Localtion</label>
                                <div className='mb-3'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    placeholder='location'
                                    aria-label='City'
                                    aria-describedby='text-addon'
                                    id='task_location'
                                    defaultValue={location.state.task_location}
                                    name='task_location'
                                    {...register('task_location', {
                                      // required: 'City is required'
                                    })}
                                  />
                                  <span>
                                    <p className='text-danger error-msg'>
                                      {errors?.task_location &&
                                        errors?.task_location?.message}
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <button
                                type='submit'
                                className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* </main> */}
    </Layout>
  )
}

export default AddEmployeeTask

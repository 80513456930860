import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useForm } from 'react-hook-form'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'
const AddEvent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const [orgData, setOrgData] = useState([])
  const navigate = useNavigate()
 const [loading, setLoading] = useState(false)
  // useEffect(() => {
  //   GetDataWithToken('getallorganisation').then(res => {
  //     if (res?.success) {
  //       setOrgData(res?.data)
  //     } else {
  //       console.log('Error', res?.message)
  //     }
  //   })
  // }, [])

  const handleAddEvent = data => {
    setLoading(true);
    PostDataWithToken('createevent', data)
      .then(res => {
        if (res?.success) {
          toast.success(res?.message);
          navigate('/event');
        } else {
          toast.error(res?.message || 'Failed to create event');
        }
      })
      .catch(error => {
        console.error('Error creating event:', error);
        toast.error('Failed to create event');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      { loading ? (
     <>
     <Loader/>
     </>
      ) : (
<div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='card mb-4'>
                <div className='card-header pb-0'>
                  <h6>Add Event</h6>
                </div>
                <div className='card-body px-0 pt-0 pb-2'>
                  <div className=''>
                    <div className=''>
                      <div className='card-body'>
                        <form
                          role='form'
                          onSubmit={handleSubmit(handleAddEvent)}
                        >
                          <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Organisation Name</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Organisation Name'
                                  aria-label='orgName'
                                  aria-describedby='text-addon'
                                  id='orgName'
                                  name='orgName'
                                  {...register('orgName', {
                                    required: 'Organisation  Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.orgName &&
                                      errors?.orgName?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Event Name</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Event Name'
                                  aria-label=' eventName'
                                  aria-describedby='text-addon'
                                  id='eventName'
                                  name='name'
                                  {...register('eventName', {
                                    required: ' Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.eventName &&
                                      errors?.eventName?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* Venue */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Venue</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Venue'
                                  aria-label='address'
                                  aria-describedby='text-addon'
                                  id='venue'
                                  name='venue'
                                  {...register('venue', {
                                    required: 'Venue is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.venue && errors?.venue?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Address</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Address'
                                  aria-label='address'
                                  aria-describedby='text-addon'
                                  id='address'
                                  name='address'
                                  {...register('address', {
                                    required: 'Address is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.address &&
                                      errors?.address?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}
                            {/* Pincode */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Pincode</label>
                              <div className='mb-3'>
                                <input
                                  type='number'
                                  className='form-control'
                                  placeholder='Pincode'
                                  aria-label='Pincode'
                                  aria-describedby='text-addon'
                                  id='pincode'
                                  onInput={e =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      6
                                    ))
                                  }
                                  name='pincode'
                                  {...register('pincode', {
                                    required: 'Pincode is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.pincode &&
                                      errors?.pincode?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* State */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>State</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='State'
                                  aria-label='State'
                                  aria-describedby='text-addon'
                                  id='state'
                                  name='state'
                                  {...register('state', {
                                    required: 'State is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger'>
                                    {errors?.state && errors?.state?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* City */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>City</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='City'
                                  aria-label='City'
                                  aria-describedby='text-addon'
                                  id='city'
                                  name='city'
                                  {...register('city', {
                                    required: 'City is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.city && errors?.city?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* location_url */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Location URL</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Location URL'
                                  aria-label='locationUrl'
                                  aria-describedby='text-addon'
                                  id='location_url'
                                  name='locationUrl'
                                  {...register('locationUrl', {
                                    required: 'Location URL is required',
                                    pattern: {
                                      value: /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/,
                                      message: 'Please enter a valid URL'
                                  }
                                  
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.locationUrl &&
                                      errors?.locationUrl?.message}
                                  </p>
                                </span>
                              </div>
                            </div>

                            {/* </div> */}

                            {/* expected visitor*/}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Event Description</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Event Description'
                                  aria-label='eventDescription'
                                  aria-describedby='text-addon'
                                  id='eventDescription'
                                  name='eventDescription'
                                  {...register('eventDescription', {
                                    required: 'event description is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.eventDescription &&
                                      errors?.eventDescription?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Expected visitor</label>
                              <div className='mb-3'>
                                <input
                                  type='number'
                                  className='form-control'
                                  placeholder='Expected Visitor'
                                  aria-label='expectedVisitor'
                                  aria-describedby='text-addon'
                                  id='expectedVisitor'
                                  name='expectedVisitor'
                                  {...register('expectedVisitor', {
                                    required: 'expected visitor is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.expectedVisitor &&
                                      errors?.expectedVisitor?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Event Head Name</label>
                              <div className='mb-3'>
                                <input
                                  type='text'
                                  className='form-control'
                                  placeholder='Event Head  Name'
                                  aria-label='eventHeadName'
                                  aria-describedby='text-addon'
                                  id='eventHeadName'
                                  name='eventHeadName'
                                  {...register('eventHeadName', {
                                    required: 'org emp Name is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.eventHeadName &&
                                      errors?.eventHeadName?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Event Head Mob number</label>
                              <div className='mb-3'>
                                <input
                                  type='number'
                                  className='form-control'
                                  placeholder='Event Head Mob Number'
                                  aria-label='eventHeadMob'
                                  aria-describedby='text-addon'
                                  id='eventHeadMob'
                                  name='eventHeadMob'
                                  {...register('eventHeadMob', {
                                    required: 'org emp number Name is required'
                                  })}
                                  onInput={e =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  onChange={e => {
                                    let value = e.target.value.trim()
                                    if (
                                      value.startsWith('0') ||
                                      value.startsWith('1') ||
                                      value.startsWith('2') ||
                                      value.startsWith('3') ||
                                      value.startsWith('4') ||
                                      value.startsWith('5')
                                    ) {
                                      value = value.substring('1')
                                    }
                                    e.target.value = value
                                  }}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.eventHeadMob &&
                                      errors?.eventHeadMob?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Event Head Email</label>
                              <div className='mb-3'>
                                <input
                                  type='email'
                                  className='form-control'
                                  placeholder='Enter Event Head Email'
                                  aria-label='eventHeadEmail'
                                  aria-describedby='text-addon'
                                  id='eventHeadEmail'
                                  name='eventHeadEmail'
                                  {...register('eventHeadEmail', {
                                    required: 'org emp email  is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.eventHeadEmail &&
                                      errors?.eventHeadEmail?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* Start Time */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>Start Date</label>
                              <div className='mb-3'>
                                <input
                                  type='date'
                                  className='form-control'
                                  placeholder='Start Time'
                                  aria-label='Start Time'
                                  aria-describedby='text-addon'
                                  id='StartTime'
                                  name='StartTime'
                                  {...register('StartTime', {
                                    required: 'Start Time is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.StartTime &&
                                      errors?.StartTime?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* </div> */}

                            {/* End Time */}
                            <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                              <label>End Date</label>
                              <div className='mb-3'>
                                <input
                                  type='date'
                                  className='form-control'
                                  placeholder='End Time'
                                  aria-label='End Time'
                                  aria-describedby='text-addon'
                                  id='EndTime'
                                  name='EndTime'
                                  {...register('EndTime', {
                                    required: 'End Time is required'
                                  })}
                                />
                                <span>
                                  <p className='text-danger error-msg'>
                                    {errors?.EndTime &&
                                      errors?.EndTime?.message}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              type='submit'
                              className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) }
      
      {/* </main> */}
    </Layout>
  )
}

export default AddEvent

import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { GetDataWithToken } from "../../constants/ApiHelper";
import { toast } from "react-toastify";

const OrganizationDetails = () => {
  const location = useLocation();
  const [data, setData] = useState();
  const navigate =  useNavigate()

  const viewDetails = () => {
    GetDataWithToken(`organisation/${location?.state?.id}`).then((res) => {
      if (res?.success) {
        setData(res?.data);
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    viewDetails();
  }, []);
  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      <div className="container-fluid py-4 dash_main">
        <div className="row">
          <div className="col-12">
            <div className="card mb-4 p-3">
              <button
                className="btn btn-outline-danger btn-sm mb-0 ms-auto"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className="card">
            <div className="row">
              <div className="employee_section">
              <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                    Org Catrgory -
                      <span> {data?.orgCategory} </span>{" "}
                    </h4>
                  </div>
                </div>
                <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                     Name -<span> {data?.name} </span>{" "}
                    </h4>
                  </div>
                </div>
                <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                    Address -
                      <span> {data?.address} </span>{" "}
                    </h4>
                  </div>
                </div>
               
              </div>
              <div className="employee_section">
              <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                    Pincode -<span> {data?.pincode} </span>{" "}
                    </h4>
                  </div>
                </div>
                <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                    State -<span> {data?.state} </span>{" "}
                    </h4>
                  </div>
                </div>
                <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                     City -
                      <span> {data?.city} </span>{" "}
                    </h4>
                  </div>
                </div>
           
              </div>
              <div className="employee_section">
              <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                    GSTIN  -<span> {data?.GSIJN} </span>{" "}
                    </h4>
                  </div>
                </div>
                <div className="col-4">
                  <div className="employee_details_sec">
                    <h4>
                     CIN  -<span> {data?.CIN} </span>{" "}
                    </h4>
                  </div>
                </div>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrganizationDetails;

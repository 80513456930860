import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CookiesProvider } from 'react-cookie'
import { saveState } from './utility/localstorage'
import { Provider } from 'react-redux'
import store from './store'
import throttle from 'lodash/throttle'

store.subscribe(
  throttle(() => {
    saveState({
      root: store.getState().root
    })
  }, 1000)
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
          <ToastContainer />
        </Provider>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
)

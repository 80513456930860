import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { PostDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'

const Scanner = () => {
  const navigate = useNavigate()
  const [cookies, setCookie] = useCookies(['taskId', 'visitorId'])
  const [taskId, setTaskId] = useState(cookies.taskId || '')
  const [visitorId, setVisitorId] = useState('')
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!cookies.taskId) {
      const newTaskId = 'defaultTaskId'
      setCookie('taskId', newTaskId, { path: '/' })
      setTaskId(newTaskId)
    }
  }, [cookies.taskId, setCookie])

  useEffect(() => {

    if (visitorId && taskId) {
      const obj = {
        visitorId,
        id: taskId
      }
      PostDataWithToken('request', obj).then(res => {
        setData(res)

      }).catch(err => {
        console.error(err)

        toast.error('An error occurred while fetching visitor details')
      })
    } else {
      setLoading(false) // Stop loading if visitorId or taskId is missing
    }
  }, [taskId, visitorId])
  

  const trackVisitor = () => {
    setLoading(true)
    if (!data?.visitor?.visitorId) {
      console.error('Visitor ID is missing.')
      setLoading(false) // Stop loading if visitor ID is missing
      return
    }
  
    const obj = {
      visitorId: data?.visitor?.visitorId,
      location: data?.employeeTask?.taskLocation,
      entryTime: '2024-05-06',
      eventid: data?.employeeTask?.eventid
    }
  
    PostDataWithToken('createtracking', obj).then(() => {
      setCookie('visitorId', data?.visitor?.visitorId, { path: '/' })
      navigate('/view-visitor')
      setLoading(false) // Stop loading after successful request
    }).catch(err => {
      console.error(err)
      setLoading(false) // Stop loading in case of an error
      toast.error('An error occurred while tracking the visitor')
    })
  }
  

  return (
    <Layout>
      {loading ? (
        <Loader/>
      ):(
<div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
          <div className='card mb-4 p-3'>
            <div className='Visitor-card-input'>
              <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                <div className='mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Visitor ID'
                    aria-label='Visitor ID'
                    aria-describedby='text-addon'
                    onChange={e => setVisitorId(e.target.value)}
                  />
                </div>
              </div>
              <div className='visitor-sub'>
                <button
                  className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                  style={{ padding: '10px 20px' }}
                  onClick={trackVisitor}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {data && (
            <div className='main-visitor-details'>
              <div className='card visitor-details mb-3'>
                <div className='row g-0'>
                  <div className='col-md-5'>
                    <div className='visitor-img'>
                      <img
                        src={data?.visitor?.photo || 'assets/img/user.png'}
                        className='img-fluid rounded-start'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='visitor_info'>
                      <h5 className='card-title'>{data?.visitor?.visitorId}</h5>
                      <h5 className='card-title'>{data?.visitor?.name}</h5>
                      <h5 className='card-title'>{data?.visitor?.category}</h5>
                      <h5 className='card-title'>
                        {data?.employeeTask?.taskLocation}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      )}
      
    </Layout>
  )
}

export default Scanner

import React from 'react'
import Layout from '../../common/Layout'

const Card = () => {
  return (
    <Layout>
      <div className='wrapper'>
        <div className='profileWidget'>
          <div className='profileBanner'>
            <div className='profileThumb'>
              <img src='https://i.ibb.co/J2tyTzV/profile.jpg' alt='' />
            </div>
          </div>
          <h5>Lisa Kristina</h5>
          <p>Maecenas Khot Loremns</p>
        </div>
      </div>
    </Layout>
  )
}

export default Card

import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'

const OrganizationDetails = () => {
  const location = useLocation()
  const [data, setData] = useState()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const viewDetails = () => {
    setLoading(true) // Set loading to true at the start
    if (!location?.state?.id) {
      toast.error("Event ID is missing.")
      setLoading(false) // Stop loading if event ID is missing
      return
    }
  
    GetDataWithToken(`event/${location.state.id}`).then(res => {
      setLoading(false) // Stop loading when the response is received
      if (res?.success) {
        setData(res?.data)
      } else {
        toast.error(res?.message)
      }
    }).catch(err => {
      setLoading(false) // Stop loading in case of an error
      console.error(err)
      toast.error('An error occurred while fetching event details')
    })
  }
  

  useEffect(() => {
    viewDetails()
  }, [])
  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      {loading ? (
        <Loader/>
      ) : (
        <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

          <div className='card'>
            <div className='row'>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Organisation Name -</label>
                      <span> {data?.orgName} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Event Name -</label>
                      <span> {data?.eventName} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Venue -</label>
                      <span> {data?.venue} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Address -</label>
                      <span> {data?.address} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Pincode -</label>
                      <span> {data?.pincode} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>State -</label>
                      <span> {data?.state} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>City -</label>
                      <span> {data?.city} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Location URL -</label>
                      <span> {data?.locationUrl} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    {' '}
                    <div className='form'>
                      <label for='text'> Event Description -</label>
                      <span> {data?.eventDescription} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'> Expected visitor -</label>
                      <span> {data?.expectedVisitor} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Event Head Name -</label>
                      <span> {data?.eventHeadName} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Event Head Mob number -</label>
                      <span> {data?.eventHeadMob} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='employee_section row'>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Event Head Email -</label>
                      <span> {data?.eventHeadEmail} </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>Start Time -</label>
                      <span>
                        {data?.StartTime &&
                          new Date(data.StartTime).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          })}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-4'>
                  <div className='employee_details_sec'>
                    <div className='form'>
                      <label for='text'>End Time -</label>
                      <span>
                        {data?.EndTime &&
                          new Date(data.EndTime).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                          })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
     
    </Layout>
  )
}

export default OrganizationDetails

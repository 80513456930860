import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import SidebarArr from './SidebarArr'
import { useCookies } from 'react-cookie';
import {sideBarArr, sideBarArrAdmin } from './SidebarArr';

const Sidebar = ({ toggleSideNav, setToggleSideNav }) => {
  const [cookies] = useCookies(

  );
  const location = useLocation()
  // const sideBarData = SidebarArr
  const sideBarData = cookies.userType === "event" ? sideBarArrAdmin : sideBarArr;

  return (
    <div>
      <aside
        className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start m-3 ${
          toggleSideNav ? 'sideOpen' : ''
        }`}
        id='sidenav-main'
      >
        <div className='sidenav-header'>
          <i
            className='fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none'
            aria-hidden='true'
            id='iconSidenav'
            onClick={() => setToggleSideNav(!toggleSideNav)}
          ></i>
          <Link to='/dashboard'>
            <img src='./assets/img/r4u_logo.png' width='100px' alt='' />
          </Link>
        </div>
        <hr className='horizontal dark mt-0' />
        <div
          className='collapse navbar-collapse w-auto max-height-vh-100 h-100'
          id='sidenav-collapse-main'
        >
          <ul className='navbar-nav'>
            {sideBarData?.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`nav-item ${
                    item.pathname.some(path => location.pathname.includes(path))
                      ? 'active'
                      : ''
                  }`}
                >
                  <Link to={item?.path} className='nav-link'>
                    <div className='icon icon-sm shadow border-radius-md text-center me-2 d-flex align-items-center justify-content-center'>
                      {item?.icon}
                    </div>
                    <span className='nav-link-text ms-1'>{item?.name}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
    </div>
  )
}

export default Sidebar

import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useForm } from 'react-hook-form'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Loader from '../../common/Loader'
const AddEmployee = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })
  const [rmData, setRmData] = useState([])
  const navigate = useNavigate()
  const [empType, setEmpType] = useState()
  const [loading,setLoading] = useState(false)


  useEffect(() => {
    GetDataWithToken('getAllEmployee').then(res => {
      if (res?.success) {
        setRmData(res?.data)
      } else {
        console.log('Error', res?.message)
      }
    })
  }, [])

  const handleAddEmp = data => {
    setLoading(true);
    PostDataWithToken('createemployee', data)
      .then(res => {
        if (res?.success) {
          toast.success(res?.message);
          navigate('/employee');
        } else {
          toast.error(res?.message || 'Failed to create employee');
        }
      })
      .catch(error => {
        console.error('Error creating employee:', error);
        toast.error('Failed to create employee');
      })
      .finally(() => setLoading(false));
  };
  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      <div className='container-fluid py-4 dash_main'>
        {loading ? (
           <>
          <Loader/>
         </>
        ) : (
          <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>

  <div className=''>
  <div className='col-12'>
    <div className='card mb-4'>
      <div className='card-header pb-0'>
        <h6>Add Employee</h6>
      </div>
      <div className='card-body px-0 pt-0 pb-2'>
        <div className=''>
          <div className=''>
            <div className='card-body'>
              <form role='form' onSubmit={handleSubmit(handleAddEmp)}>
                <div className='row'>
                  {/* Employee Type */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Employee Type</label>
                    <div className='mb-3'>
                      <select
                        className='form-control'
                        id='emp_type'
                        name='emp_type'
                        {...register('emp_type', {
                          required: 'Employee Type is required',
                          onChange: e => setEmpType(e.target.value)
                        })}
                      >
                        <option key='' value=''>
                          Select Employee Type
                        </option>
                        <option key='employee' value='employee'>
                          Permanent
                        </option>
                        <option key='volunteer' value='volunteer'>
                          Volunteer
                        </option>
                      </select>
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.emp_type &&
                            errors?.emp_type?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* Reporting Manager */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Reporting Manager</label>
                    <div className='mb-3'>
                      <select
                        className='form-control'
                        id='Reporting_Manager'
                        name='Reporting_Manager'
                        {...register('Reporting_Manager')}
                      >
                        <option key='' value=''>
                          Select Reporting Manager
                        </option>
                        {rmData?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.first_name + ' ' + item?.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* First Name */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>First Name</label>
                    <div className='mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='First Name'
                        aria-label='First Name'
                        aria-describedby='text-addon'
                        id='first_name'
                        name='first_name'
                        {...register('first_name', {
                          required: 'First Name is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.first_name &&
                            errors?.first_name?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* Last Name */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Last Name</label>
                    <div className='mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Last Name'
                        aria-label='Last Name'
                        aria-describedby='text-addon'
                        id='last_name'
                        name='last_name'
                        {...register('last_name', {
                          required: 'Last Name is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.last_name &&
                            errors?.last_name?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* Email */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Email</label>
                    <div className='mb-3'>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        aria-label='Email'
                        aria-describedby='text-addon'
                        id='email'
                        name='email'
                        {...register('email', {
                          required: 'Email is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.email && errors?.email?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* Mobile */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Mobile Number</label>
                    <div className='mb-3'>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Mobile Number'
                        aria-label='Mobile Number'
                        aria-describedby='text-addon'
                        id='contact'
                        pattern='[0-9]{10}'
                        onInput={e =>
                          (e.target.value = e.target.value.slice(
                            0,
                            10
                          ))
                        }
                        name='contact'
                        {...register('contact', {
                          required: 'Mobile Number is required',
                          minLength: {
                            value: 10
                          }
                        })}
                        onChange={e => {
                          let value = e.target.value.trim()
                          if (
                            value.startsWith('0') ||
                            value.startsWith('1') ||
                            value.startsWith('2') ||
                            value.startsWith('3') ||
                            value.startsWith('4') ||
                            value.startsWith('5')
                          ) {
                            value = value.substring('1')
                          }
                          e.target.value = value
                        }}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.contact &&
                            errors?.contact?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* Date Of Birth */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>DOB</label>
                    <div className='mb-3'>
                      <input
                        type='date'
                        className='form-control'
                        placeholder='Date Of Birth'
                        aria-label='Date Of Birth'
                        aria-describedby='text-addon'
                        id='dob'
                        name='dob'
                        {...register('dob', {
                          required: 'Date Of Birth is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.dob && errors?.dob?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* Gender */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Gender</label>
                    <div className='mb-3'>
                      <select
                        className='form-control'
                        id='gender'
                        name='gender'
                        {...register('gender', {
                          required: 'Gender is required'
                        })}
                      >
                        <option key='' value=''>
                          Select Gender
                        </option>
                        <option key='male' value='male'>
                          Male
                        </option>
                        <option key='female' value='female'>
                          Female
                        </option>
                        <option key='other' value='other'>
                          Other
                        </option>
                      </select>
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.gender && errors?.gender?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* PAN Card */}
                  {empType === 'employee' && (
                    <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                      <label>PAN Card</label>
                      <div className='mb-3'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='PAN Card'
                          aria-label='PAN Card'
                          maxLength={'10'}
                          aria-describedby='text-addon'
                          id='pan_card'
                          name='pan_card'
                          {...register('pan_card', {
                            pattern: {
                              value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                              message: 'Invalid Pan Number'
                            },
                            required: 'PAN Card is required'
                          })}
                          onInput={e =>
                            (e.target.value =
                              e.target.value.toUpperCase())
                          }
                          style={{ textTransform: 'uppercase' }}
                        />
                        <span>
                          <p className='text-danger error-msg'>
                            {errors?.pan_card &&
                              errors?.pan_card?.message}
                          </p>
                        </span>
                      </div>
                    </div>
                  )}
                  {/* Aadhar Card */}
                  {empType === 'employee' && (
                    <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Aadhar Card</label>
                    <div className='mb-3'>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Aadhar Card'
                        aria-label='Aadhar Card'
                        aria-describedby='text-addon'
                        onInput={e =>
                          (e.target.value = e.target.value.slice(
                            0,
                            12
                          ))
                        }
                        id='adhar_card'
                        name='adhar_card'
                        {...register('adhar_card', {
                          required: 'Aadhar Card is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.adhar_card &&
                            errors?.adhar_card?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  )}
                  
                  {/* Pincode */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Pincode</label>
                    <div className='mb-3'>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Pincode'
                        aria-label='Pincode'
                        aria-describedby='text-addon'
                        id='pincode'
                        onInput={e =>
                          (e.target.value = e.target.value.slice(
                            0,
                            6
                          ))
                        }
                        name='pincode'
                        {...register('pincode', {
                          required: 'Pincode is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.pincode &&
                            errors?.pincode?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* State */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>State</label>
                    <div className='mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='State'
                        aria-label='State'
                        aria-describedby='text-addon'
                        id='state'
                        name='state'
                        {...register('state', {
                          required: 'State is required'
                        })}
                      />
                      <span>
                        <p className='text-danger'>
                          {errors?.state && errors?.state?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* City */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>City</label>
                    <div className='mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='City'
                        aria-label='City'
                        aria-describedby='text-addon'
                        id='city'
                        name='city'
                        {...register('city', {
                          required: 'City is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.city && errors?.city?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* Start Time */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Joining Date</label>
                    <div className='mb-3'>
                      <input
                        type='date'
                        className='form-control'
                        placeholder='Start Time'
                        aria-label='Start Time'
                        aria-describedby='text-addon'
                        id='StartTime'
                        name='StartTime'
                        {...register('StartTime', {
                          required: 'Start Time is required'
                        })}
                      />
                      <span>
                        <p className='text-danger error-msg'>
                          {errors?.StartTime &&
                            errors?.StartTime?.message}
                        </p>
                      </span>
                    </div>
                  </div>
                  {/* End Time */}
                  <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                    <label>Exit Date</label>
                    <div className='mb-3'>
                      <input
                        type='date'
                        className='form-control'
                        placeholder='End Time'
                        aria-label='End Time'
                        aria-describedby='text-addon'
                        id='EndTime'
                        name='EndTime'
                        {...register('EndTime')}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type='submit'
                    className='btn btn-outline-primary btn-sm mb-0 ms-auto'
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        
        </div>
        )}
       
      </div>
    </Layout>
  )
}

export default AddEmployee

import React from 'react'
import Layout from './Layout'
import Footer from './Footer'


const Profile = () => {
  return (  
    <Layout>
      <div
        className='container-fluid profile mt-7'
        style={{ position: 'relative' }}
      >
        <div
          className='page-header min-height-300 border-radius-xl mt-4'

          // style="background-image: url('../assets/img/curved-images/curved0.jpg'); background-position-y: 50%;"
        >
          <span className='mask bg-gradient-primary opacity-6'></span>
        </div>
        <div className='card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden mb-4'>
          <div className='row gx-4'>
            <div className='col-auto'>
              <div className='avatar avatar-xl position-relative'>
                <img
                  src='../assets/img/bruce-mars.jpg'
                  alt='profile_image'
                  className='w-100 border-radius-lg shadow-sm'
                />
              </div>
            </div>
            <div className='col-auto my-auto'>
              <div className='h-100'>
                <h5 className='mb-1'>{}</h5>
                <p className='mb-0 font-weight-bold text-sm'>CEO / Co-Founder</p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3'>
              <div className='nav-wrapper position-relative end-0'>
                <ul
                  className='nav nav-pills nav-fill p-1 bg-transparent'
                  role='tablist'
                >
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-xl-4'>

          </div>
          <div className='col-12 col-xl-4'>
            <div className='card h-100'>
              <div className='card-header pb-0 p-3'>
                <div className='row'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Profile

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/Auth/Login'
import Dashboard from '../pages/Dashboard/Dashboard'
import Employee from '../pages/Employee/Employee'
import AddEmployee from '../pages/Employee/AddEmployee'
import Profile from '../common/Profile'
import CheckAuth from './CheckAuth'
import EditEmployee from '../pages/Employee/EditEmployee'
import EmployeeDetails from '../pages/Employee/EmployeeDetails'
import Organization from '../pages/Organisation/Organization'
import AddOrganization from '../pages/Organisation/AddOrganization'
import EditOrganization from '../pages/Organisation/EditOrganization'
import OrganizationDetails from '../pages/Organisation/OrganizationDetails'
import Event from '../pages/Event/Event'
import AddEvent from '../pages/Event/AddEvent'
import EditEvent from '../pages/Event/EditEvent'
import ViewDetails from '../pages/Event/ViewDetails'
import EmployeeTask from '../pages/EmployeeTask/EmployeeTask'
import AddEmployeeTask from '../pages/EmployeeTask/AddEmployeeTask'
import EditEmployeeTask from '../pages/EmployeeTask/EditEmployeeTask'
import EmployeeTaskDetails from '../pages/EmployeeTask/EmployeeTaskDetails'
import Visitors from '../pages/Visitors/Visitors'
import AddVisitors from '../pages/Visitors/AddVisitors'
import EditVisitors from '../pages/Visitors/EditVisitors'
import VisitorsDetails from '../pages/Visitors/VisitorsDetails'
import Scanner from '../pages/Scanner/Scanner'
import Card from '../pages/Visitors/Card'
import CardContainer from '../pages/Visitors/CardContainer'

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />{' '}
      <Route element={<CheckAuth />}>
        <Route path='/dashboard' element={<Dashboard />} />

        {/* Employee */}
        <Route path='/employee' element={<Employee />} />
        <Route path='/add-employee' element={<AddEmployee />} />
        <Route path='/edit-employee' element={<EditEmployee />} />
        <Route path='/view-employee' element={<EmployeeDetails />} />

        {/*Organization  */}
        <Route path='/organization' element={<Organization />} />
        <Route path='/add-organization' element={<AddOrganization />} />
        <Route path='/edit-organization' element={<EditOrganization />} />
        <Route path='/view-organization' element={<OrganizationDetails />} />

        {/*Organization  */}
        <Route path='/event' element={<Event />} />
        <Route path='/add-event' element={<AddEvent />} />
        <Route path='/edit-event' element={<EditEvent />} />
        <Route path='/view-event' element={<ViewDetails />} />

        {/*employee task  */}
        <Route path='/employe-task' element={<EmployeeTask />} />
        <Route path='/add-employe-task' element={<AddEmployeeTask />} />
        <Route path='/edit-employe-task' element={<EditEmployeeTask />} />
        <Route path='/view-employe-task' element={<EmployeeTaskDetails />} />

        {/*Visitors  */}
        <Route path='/visitor' element={<Visitors />} />
        <Route path='/add-visitor' element={<AddVisitors />} />
        <Route path='/edit-visitor' element={<EditVisitors />} />
        <Route path='/view-visitor' element={<VisitorsDetails />} />
        <Route path='/view-card' element={<Card />} />
        <Route path='/card' element={<CardContainer />} />

        {/* Profile */}
        <Route path='/profile' element={<Profile />} />

        {/* Scanner */}
        <Route path='/scan' element={<Scanner />} />
      </Route>
    </Routes>
  )
}

export default AllRoutes

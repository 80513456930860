import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper'
import { toast } from 'react-toastify'
import { useCookies } from 'react-cookie'
import Loader from '../../common/Loader'

const VisitorsDetails = () => {
  const location = useLocation()
  const [data, setData] = useState(null)
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(2)
  const [visitorTracking, setVisitorTracking] = useState([])
  const [cookies] = useCookies(['visitorId'])
  const [retry, setRetry] = useState(false)
  const [loading ,setLoading] = useState(false)

  const visitorId = location?.state?.visitorId || cookies.visitorId
  console.log('VisitorId', visitorId)

  const viewVisitorDetails = () => {
    setLoading(true) // Set loading to true at the start
    if (!visitorId) {
      toast.error("Visitor ID is missing.")
      setLoading(false) // Stop loading if visitorId is missing
      return
    }
  
    // Fetch visitor details
    GetDataWithToken(`visitors/${visitorId}`).then(res => {
      if (res?.success) {
        setData(res?.data)
      } else {
        toast.error(res?.message)
      }
    }).catch(err => {
      console.error(err)
      toast.error('An error occurred while fetching visitor details')
    })
  
    // Fetch visitor tracking data
    PostDataWithToken(`gettracking/${visitorId}`).then(res => {
      if (res?.success) {
        // Assuming each tracking item has a timestamp field called `timestamp`
        const sortedData = res?.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        setVisitorTracking(sortedData)
      } else {
        toast.error(res?.message)
      }
    }).catch(err => {
      console.error(err)
      toast.error('An error occurred while fetching visitor tracking data')
    }).finally(() => {
      setLoading(false) // Stop loading once all operations are complete
    })
  }
  

  useEffect(() => {
    if (visitorId) {
      setRetry(true)
      viewVisitorDetails()
    } else {
     
    }
  }, [visitorId, retry])

  useEffect(() => {
    if (retry && !visitorId) {
      const retryInterval = setInterval(() => {
        if (cookies.visitorId) {
          clearInterval(retryInterval)
          setRetry(false)
        }
      }, 1000)

      return () => clearInterval(retryInterval)
    }
  }, [retry, cookies.visitorId])

  return (
    <Layout>
      {/* <main className='main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg '> */}
      {loading ? (
        <Loader/>
      ) : (
        <div className='container-fluid py-4 dash_main'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <button
                className='btn btn-outline-danger btn-sm mb-0 ms-auto'
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
          <div className='col-12'>
            <div className='card mb-4 p-3'>
              <div className='visitor_data'>
              <div className='visitor_info' onClick={() => setActiveStep(1)}>
                  <button
                    className={
                      activeStep === 1
                        ? 'btn btn-outline-primary active'
                        : 'btn btn-outline-primary'
                    }
                  >
                    Visitor Details
                  </button>
                </div>
                <div className='visitor_info' onClick={() => setActiveStep(2)}>
                  <button
                    className={
                      activeStep === 2
                        ? 'btn btn-outline-primary active'
                        : 'btn btn-outline-primary'
                    }
                  >
                    Visitor Tracking
                  </button>
                </div>
               
              </div>
            </div>
          </div>

          {/* Details */}
          {activeStep === 1 && (
            <div className='col-12'>
              <div className='card'>
                <div className='employee_section row'>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Visitor Name -</label>
                        <span> {data?.name} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Company -</label>
                        <span> {data?.company} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Visitor Number -</label>
                        <span> {data?.contact} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='employee_section row'>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'> Visitor Email -</label>
                        <span> {data?.email} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Employee Type -</label>
                        <span> {data?.category} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Gender -</label>
                        <span> {data?.gender} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='employee_section row'>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>City -</label>
                        <span> {data?.city} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Ticket/invite/Guestpass -</label>
                        <span> {data?.ticket} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Hostess -</label>
                        <span> {data?.hostess} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='employee_section row'>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'>Professions(Deals In) -</label>
                        <span> {data?.professions} </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-4'>
                    <div className='employee_details_sec'>
                      <div className='form'>
                        <label for='text'> Profile Photo -</label>
                        <span>
                          {' '}
                          <img
                            src={
                              data?.photo
                            }
                            alt=''
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Tracking Data */}
          {activeStep === 2 && (
            <div className='row'>
              <div className='col-12'>
                <div className='card mb-4'>
                  <div className='card-header pb-0'>
                    <h6>Visitors</h6>
                  </div>
                  <div className='card-body pt-0 pb-2'>
                    <div className='table-responsive p-0'>
                      <table className='table align-items-center mb-0'>
                        <thead>
                          <tr>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Event Name
                            </th>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Visitor Name
                            </th>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Visitor Category
                            </th>
                            <th className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7'>
                              Location
                            </th>
                            {/* <th className='text-secondary opacity-7'>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {visitorTracking?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  <p className='text-xs font-weight-bold mb-0'>
                                    {item?.eventname}
                                  </p>
                                </td>
                                <td>
                                  <p className='text-xs font-weight-bold mb-0'>
                                    {item?.name}
                                  </p>
                                </td>
                                <td>
                                  <p className='text-xs font-weight-bold mb-0'>
                                    {item?.category}
                                  </p>
                                </td>
                                <td>
                                  <p className='text-xs font-weight-bold mb-0'>
                                    {item?.location}
                                  </p>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      )}
     
    </Layout>
  )
}

export default VisitorsDetails
